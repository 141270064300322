import './style.scss'
import React from 'react'
import { Row, Col, Fade, Container } from 'reactstrap'
import { connect } from 'react-redux'
import { QuotationPageProps, QuotationTypeCatalog } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import CardButton from '#components/CardButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { setQuotationType } from '#actions/quotation'
import { Palettes } from '#theme/export'
import ReduxState from '#interfaces/ReduxState'
import { QuotationPageContext } from '#screens/Quotation'

interface QuotationTypeInjected {
    type: QuotationTypeCatalog
}


class QuotationType extends React.PureComponent<QuotationPageProps & QuotationTypeInjected & ThunkDispatchProp> {

    state = {
        loading: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    selectType = (type: QuotationTypeCatalog) => async () => {
        const { dispatch, onFinish } = this.props
        dispatch(setQuotationType(type))
        onFinish()
    }

    render() {
        const { type } = this.props 
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: this.state.loading }) &&
                    <div className="container-quotationtype">
                        <Container>
                            <Row>
                                <Col className="mb-2" xs={12}>
                                    <h4>Selecciona el tipo de servicio</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={3} className="mt-1">
                                    <Fade>
                                        <CardButton selected={type === QuotationTypeCatalog.HOME_SERVICE} icon={<FontAwesomeIcon color={Palettes.Mono.gray} size="6x" icon={faTruck} />} name={'Servicio a domicilio'} onClick={this.selectType(QuotationTypeCatalog.HOME_SERVICE)} />
                                    </Fade>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3} className="mt-1">
                                    <Fade>
                                        <CardButton selected={type === QuotationTypeCatalog.LOCAL_SERVICE} icon={<FontAwesomeIcon color={Palettes.Mono.gray} size="6x" icon={faWarehouse} />} name={'Servicio en sucursal'} onClick={this.selectType(QuotationTypeCatalog.LOCAL_SERVICE)} />
                                    </Fade>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationTypeInjected>((state: ReduxState) => ({ type: state.quotation.selection.type }), dispatchMap)(QuotationType)