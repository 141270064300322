import React, { Fragment } from 'react'
import './style.scss'
import { Row, Col, Container } from 'reactstrap'
import { NAVIGATION, COMPANY } from '#constants'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
// import MaterialIcon from 'material-icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const Footer = () => (
    <div className="container-footer">
        <Container>
            <Row>
                <Col className="footer-nav d-flex justify-content-center justify-content-md-start" xs={12} md={6}>
                    {NAVIGATION.map((nav) => (
                        <Fragment key={nav.route || nav.href}>
                            {nav.route ? 
                                <Link to={nav.route}>
                                    <FormattedMessage id={nav.label} />
                                </Link>
                            :
                                <a href={nav.href}  target="_self">
                                    <FormattedMessage id={nav.label} />
                                </a>
                            }
                        </Fragment>
                    ))}
                </Col>
                <Col className="footer-contact d-flex mt-3 mt-md-0" xs={12} md={6}>
                    <Row>
                        <Col xs={12} className="footer-social d-flex justify-content-center justify-content-md-end">
                            <a href={COMPANY.WHATSAPP}>
                                <FontAwesomeIcon size="2x" icon={faWhatsapp} />
                            </a>
                            <a href={COMPANY.FACEBOOK}>
                                <FontAwesomeIcon size="2x" icon={faFacebook} />
                            </a>
                            <a href={COMPANY.INSTAGRAM}>
                                <FontAwesomeIcon size="2x" icon={faInstagram} />
                            </a>
                        </Col>
                        <Col xs={12} className="footer-social d-flex justify-content-center justify-content-md-end">
                            <span className="footer-bar" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex flex-column footer-simple-contact align-items-center align-items-md-end">
                            <a href={`mailto:${COMPANY.EMAIL}`}>
                                {COMPANY.EMAIL}
                                <FontAwesomeIcon icon={faEnvelope} />
                            </a>
                            <a href={`tel:${COMPANY.PHONE}`}>
                                {COMPANY.PHONE}
                                <FontAwesomeIcon icon={faPhone} />
                            </a>
                            <a href={`tel:${COMPANY.PHONE2}`}>
                                {COMPANY.PHONE2}
                                <FontAwesomeIcon icon={faPhone} />
                            </a>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
            <Row>
                <Col className="text-center footer-disclaimer" xs={12}>
                    <p>Ⓒ 2014 ©Techhouse. Todos los derechos reservados</p>
                </Col>
            </Row>
        </Container>
    </div>
)

export default Footer