import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions';
import _ from 'lodash';
import ReduxState, { PaymentState } from '#interfaces/ReduxState';
import { Row, Col, Container, ModalHeader, ModalBody, Modal, ModalFooter, Button, Spinner } from 'reactstrap';
import { ROUTES, PAYPAL_FAIL_FLAG, PAYPAL_TOKEN } from '#constants';
import PaymentCard from '#components/PaymentCard';
import { PaymentMethodEnum } from '#interfaces/Payment';
import QueryString from 'query-string'
import Busy from '#components/Busy';
import { confirmPaypal, setPaypalUrl, payConekta } from '#actions/payment';
import { Cart, Appointment, Part, Product } from '#interfaces/Quotation';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle, faCreditCard, faUser, faCalendarDay, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConektaForm, ConektaCardInput, ConektaExpMonthInput, ConektaExpYearInput, ConektaNameInput, ConektaCVCInput } from '#helpers/conekta';
import { ConektaTokenResponse } from '#helpers/conekta/interfaces';

interface PaymentPropsInjected{
    payment: PaymentState
    cart: Cart
    appointment: Appointment
    part: Part
    product: Product
}

/**
 * This Login Screen Component implements the login screen
 * @type React.Component
 */
class Payment extends React.Component<PaymentPropsInjected & ThunkDispatchProp & RouteComponentProps<any>> {

    state = {
        paying: false,
        fail: false,
        physical_payment: false,
        formkey: `${(new Date()).getTime()}_${Math.random()}`
    }

    async componentDidMount() {
        const { history, payment, dispatch } = this.props
        const query = QueryString.parse(history.location.search)
        const token = query[PAYPAL_TOKEN] as string
        const fail  = query[PAYPAL_FAIL_FLAG] as string
        const paymenttype = payment.conekta || payment.paypal

        window.scrollTo(0,0)
        
        if (payment.payment_method === PaymentMethodEnum.CASH || payment.payment_method === PaymentMethodEnum.PHYSICAL_TERMINAL) {
            this.setState({ physical_payment: true })
        } else if (paymenttype && paymenttype.charge) {
            history.replace(ROUTES.ROOT)
        } else if (fail) {
            this.setState({ fail: true })
        } else if (token) {
            this.setState({ paying: true })
            try {
                await dispatch(confirmPaypal(token))
                this.setState({ paying: false })
            } catch (e) {
                history.replace(ROUTES.ROOT)
            }
        } else if (_.isEmpty(payment.preview) || _.isNil(payment.payment_method)) {
            history.replace(ROUTES.ROOT)
        }

    }

    gotoPaypal = () => {
        const { payment, dispatch } = this.props

        if (payment && payment.paypal && payment.paypal.approve_url) {
            dispatch(setPaypalUrl(null))
            window.location.href = payment.paypal.approve_url.href
        }
    }

    payConekta = async (serialized: ConektaTokenResponse) => {
        const { dispatch, cart } = this.props
        this.setState({ paying: true, fail: false })
        try {
            await dispatch(payConekta(cart, serialized.token.id))
            this.setState({ paying: false })
        } catch (e) {
            this.setState({ fail: true, paying: false, formkey: `${(new Date()).getTime()}_${Math.random()}` })
        }
        window.scrollTo(0,0)
    }

    goHome = () => {
        const { history } = this.props
        history.push(ROUTES.ROOT)
    }

    goBack = () => {
        const { history } = this.props
        history.goBack()
    }

    render () {
        const { payment, cart, appointment, part, product } = this.props
        const { paying, fail, formkey, physical_payment } = this.state
        const paypalredirect = !!( payment && payment.payment_method === PaymentMethodEnum.PAYPAL && (payment.paypal ? payment.paypal.approve_url : false))
        const paymenttype = payment.conekta || payment.paypal
        const payed = paymenttype && paymenttype.charge ? true : false
        // const charge = payment.preview ? payment.preview.amount : payment.paypal ? payment.paypal.charge.amount : 0
        return (
            <div className="screen screen-payment">
                <Busy busy={!payment || !payment.preview}>
                    <Container>
                        <Row>
                            <Col xs={12} className="mb-3">
                                {physical_payment &&
                                    <>
                                        <h1>
                                            <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></span>
                                            &nbsp;¡Cita creada exitosamente!
                                        </h1>
                                    </>
                                }
                                {payed &&
                                    <>
                                        <h1>
                                            <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></span>
                                            &nbsp;¡Pago exitoso!
                                        </h1>
                                    </>
                                }
                                {fail &&
                                    <>
                                        <h1>
                                            <span className="text-danger"><FontAwesomeIcon icon={faExclamationCircle} /></span>
                                            &nbsp;Pago Fallido
                                        </h1>
                                        <h6>El pago con la tarjeta no pudo ser procesado. Intenta con otra tarjeta o selecciona otro método de pago</h6>
                                    </>
                                }
                                {!fail && !payed && !physical_payment &&
                                    <>
                                        <h1>Resumen de pago</h1>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {payment  && <PaymentCard payed={payed} part={part} product={product} appointment={appointment} cart={cart} payment={payment} />}
                            </Col>
                        </Row>
                        {!payed && payment.payment_method === PaymentMethodEnum.CONEKTA && 
                            <Row>
                                <Col xs={12}>
                                    <hr />
                                </Col>
                                <Col xs={12}>
                                    <h2>Datos de pago</h2>
                                    <h6>Pago con tarjeta</h6>
                                </Col>
                                <Col xs={12}>
                                    <ConektaForm key={formkey} publicKey={process.env.REACT_APP_CONEKTAKEY} language="es" onSubmit={this.payConekta}>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <ConektaNameInput placeholder="Ej. Juan Perez" prepend={<FontAwesomeIcon icon={faUser} />} label="Nombre en la tarjeta" />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <ConektaCardInput placeholder="####-####-####-####" prepend={<FontAwesomeIcon icon={faCreditCard} />} label="Número de tarjeta de crédito/débito" />
                                            </Col>
                                            <Col xs={6} sm={4} md={3} lg={2}>
                                                <ConektaExpMonthInput placeholder="MM" prepend={<FontAwesomeIcon icon={faCalendarDay} />} label="Mes de expiración" />
                                            </Col>
                                            <Col xs={6} sm={4} md={3} lg={2}>
                                                <ConektaExpYearInput placeholder="YY" prepend={<FontAwesomeIcon icon={faCalendar} />} label="Año de expiración" />
                                            </Col>
                                            <Col xs={6} sm={4} md={3} lg={2}>
                                                <ConektaCVCInput prepend={'***'} label="Código CVC" />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={12} md={4} className="d-flex justify-content-center">
                                                {!paying && !payed && <Button block type="button" color="white" onClick={this.goBack}>Regresar</Button>}
                                            </Col>
                                            <Col xs={12} md={{ size: 4, offset: 4 }} className="d-flex justify-content-center mt-3 mt-md-0">
                                                {paying ? <Spinner color="highlight" /> : <Button block type="submit" color="primary">Pargar</Button>}
                                            </Col>
                                        </Row>
                                    </ConektaForm>
                                </Col>
                            </Row>
                        }
                        {(payed || physical_payment) && 
                            <>
                                {physical_payment && <Row>
                                    <Col className="mt-3" xs={12}>
                                        <h5>El monto por pagar deberás cubrirlo contra entrega al terminar tu servicio.</h5>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col className="mt-1" xs={12}>
                                        <p>Recuerda que siempre puedes consultar el estado de tu servicio en la sección <b>Mi Reparación</b> usando tu <b>Código</b></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={{ size: 4, offset: 8 }}>
                                        <Button block onClick={this.goHome} color="primary">Terminar</Button>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Container>
                </Busy>
                <Modal isOpen={paypalredirect}>
                    <ModalHeader>Redirigiendo a Paypal</ModalHeader>
                    <ModalBody>
                        Para continuar con tu pago te vamos a redirigir a la página de Paypal. Una vez terminado tu pago, automaticamente regresarás a 
                        esta página para confirmar que tu pago se realizó con éxito.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.gotoPaypal}>Ir a Paypal</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect<PaymentPropsInjected>((state: ReduxState) => ({
    payment: state.payment,
    cart: state.quotation.selection.cart,
    appointment: state.quotation.selection.appointment,
    part: state.quotation.selection.part,
    product: state.quotation.selection.product
}), dispatchMap)(withRouter(Payment));
