import "./style.scss";
import React, { ReactNode } from "react";
import { Spinner } from "reactstrap";
import { formatCurrency } from "#helpers/utils";

interface CardButtonProps {
  name?: string;
  onClick?: (() => void) | (() => Promise<void>);
  href?: string;
  busy?: boolean;
  alt?: boolean;
  selected?: boolean;
  subname?: string;
  icon?: ReactNode;
  image?: string;
  image_alt?: string;
  fixed?: boolean;
  price?: number;
}

const CardButton = (props: CardButtonProps) => {
  if (props.href) {
    const href = props.href.replace(new RegExp('/$'), '');
    return (
      <a
        href={`${process.env.PUBLIC_URL}${href}`}
        className={`component-cardbutton ${props.alt ? "alt" : ""} ${
          props.selected ? "selected" : ""
        }`.trim()}
      >
        {props.image && (
          <img
            src={encodeURI(props.image)}
            alt={props.image_alt || "img"}
            className={`image ${!props.name ? "nameless" : ""} ${
              props.fixed ? "fixed" : ""
            }`}
          />
        )}
        {props.icon && <span className="icon">{props.icon}</span>}
        {props.name && (
          <div className="card-content">
            {props.name && props.subname ? (
              <p>{props.name || ""}</p>
            ) : (
              <p>{props.name ? props.name : ""}</p>
            )}
            {props.subname && <i>{props.subname}</i>}
            {props.price && formatCurrency(props.price, "MXN")}
            {props.busy && <Spinner size="sm" color="secondary" />}
          </div>
        )}
      </a>
    );
  }

  return (
    <button
      onClick={props.onClick}
      className={`component-cardbutton ${props.alt ? "alt" : ""} ${
        props.selected ? "selected" : ""
      }`.trim()}
    >
      {props.image && (
        <img
          src={encodeURI(props.image)}
          alt={props.image_alt || "img"}
          className={`image ${!props.name ? "nameless" : ""} ${
            props.fixed ? "fixed" : ""
          }`}
        />
      )}
      {props.icon && <span className="icon">{props.icon}</span>}
      {props.name && (
        <div className="card-content">
          {props.name && props.subname ? (
            <p>{props.name || ""}</p>
          ) : (
            <p>{props.name ? props.name : ""}</p>
          )}
          {props.subname && <i>{props.subname}</i>}
          {props.price && formatCurrency(props.price, "MXN")}
          {props.busy && <Spinner size="sm" color="secondary" />}
        </div>
      )}
    </button>
  );
};

export default CardButton;

