import './style.scss'
import React, { useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { QuotationSelection } from '#interfaces/ReduxState'
import { PaymentMethodEnum } from '#interfaces/Payment'
import Barcode from 'react-barcode'

interface ReceiptProps {
    selection: QuotationSelection
    payment: PaymentMethodEnum
}



const Receipt = (props: ReceiptProps) => {

    const code = useMemo(() => props.selection.appointment.barcode, [props.selection])
    const part = useMemo(() => {
        return `${props.selection.part ? props.selection.part.name : props.selection.product.name}${props.selection.product.variation ? ` ${props.selection.product.variation.name}` : ''}`
    }, [props.selection])
    
    const service = useMemo(() => {
        if (props.selection.model) {
            return props.selection.model.name
        } else if (props.selection.line) {
            return `${props.selection.brand.name} ${props.selection.line.name}`
        } else {
            return `${props.selection.brand.name}`
        }
    }, [props.selection])

    const paylocal = useMemo(() => {
        return props.payment === PaymentMethodEnum.CASH || props.payment === PaymentMethodEnum.PHYSICAL_TERMINAL
    }, [props.payment])

    


    return (
        <div className="component-receipt">
            <Row>
                <Col xs={12} lg={{ size: 10, offset: 1 }}>
                    <Row className="mb-5">
                        <Col xs={12} md={6} className="d-flex justify-content-end">
                            <Barcode value={code} options={{ width: '100%', height: 'auto', format: 'EAN13' }} />
                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column">
                            <div className="personal-info">
                                <h4>Código</h4>
                                <h3>{code}</h3>
                            </div>
                            <div className="service-info">
                                <h4>{part}</h4>
                                <p>{service}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mb-5" xs={12}>
                            {paylocal && <p>El monto por pagar deberá ser cubierto contra entrega al terminar tu servicio.</p>}
                            <p>Recuerda que siempre puedes consultar el estado de tu servicio en la sección <b>Mi Reparación</b> usando tu Código</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Receipt
