import './style.scss'
import React from 'react'

interface InfographProps {
    title: string
    subtitle: string
    icon: React.ReactNode
}

const Infograph = (props: InfographProps) => (
    <div className="component-inforgraph">
        <div className="ig-icon">
            {props.icon}
        </div>
        <h3>{props.title}</h3>
        <p>{props.subtitle}</p>
    </div>
)

export default Infograph
