import React from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Container
} from 'reactstrap'
import Img from '#components/Image'
import './style.scss';
import { NAVIGATION } from '#constants';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class MainBar extends React.PureComponent {
    state = {
        isOpen: false
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        const { isOpen } = this.state; 
        return (
            <div className="container-mainbar bg-primary">
                <Container>
                    <Navbar color="primary" light expand="md">
                        <NavbarBrand href="/">
                            <Img className="logo" src="logo.png" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {NAVIGATION.map((nav) => (
                                <NavItem key={nav.route || nav.href}>
                                    {nav.route ? 
                                        <Link to={nav.route}>
                                            <FormattedMessage id={nav.label} />
                                        </Link>
                                    :
                                        <a href={nav.href} target="_self">
                                            <FormattedMessage id={nav.label} />
                                        </a>
                                    }
                                </NavItem>
                            ))}
                            {/* <NavItem>
                            <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                Options
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                Option 1
                                </DropdownItem>
                                <DropdownItem>
                                Option 2
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                Reset
                                </DropdownItem>
                            </DropdownMenu>
                            </UncontrolledDropdown> */}
                        </Nav>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        )
    }
}

export default MainBar