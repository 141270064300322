import './style.scss'
import React from 'react'

interface TitleBarProps {
    title: string
    subtitle?: string
    icon?: React.ReactNode
    invert?: boolean
    simple?: boolean
}

const TitleBar = (props: TitleBarProps) => {

    return (
        <div className={`component-titlebar ${props.invert ? 'invert' : ''} ${props.simple ? 'simple' : ''}`}>
            <div className="title-row">
                <hr />
                <div className="title-area">
                    <div className="ta-icon">
                        {props.icon}
                    </div>
                    <div className="d-none d-md-block ta-title">
                        <h4>{props.title}</h4>
                    </div>
                    <div className="d-block d-md-none ta-title">
                        <p>{props.title}</p>
                    </div>
                </div>
                <hr />
            </div>
            <div className="subtitle-row">
                <h5>{props.subtitle}</h5>
            </div>
        </div>
    )
}


export default TitleBar
