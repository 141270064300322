import './style.scss'
import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { AppointmentSlot, Office, QuotationPageProps } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { setSelectedAppointmentSlot, getAppointmentSlots } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha } from '#helpers/utils'
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/es';
import { QuotationPageContext } from '#screens/Quotation'
import moment from 'moment'
import ColumnRadio from '#components/ColumnRadio'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import { GAEVENT_CATS } from '#constants'


interface QuotationAppointmentSlotsInjectedProps {
    appointmentslots?: AppointmentSlot[]
    office: Office
    slot: AppointmentSlot
    date: string
}


class QuotationAppointmentSlots extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuotationAppointmentSlotsInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null,
        busy: true,
    }

    componentDidMount() {
        this.loadAppointmentSlot(moment().add(12, 'hour').toDate())
        window.scrollTo(0, 0)
    }

    clickAppointmentSlot = async (slot: AppointmentSlot) => {
        const { dispatch, onFinish } = this.props
        this.setState({ loading: slot.idAppointmentslot })
        await dispatch(setSelectedAppointmentSlot(slot))
        this.props.analytics.gtag('event', 'set_appointment_slot', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: `${slot.start}-${slot.end}` })
        onFinish()
    }

    loadAppointmentSlot = async (date: Date) => {
        const { dispatch, office } = this.props
        this.setState({ busy: true, date })
        await dispatch(getAppointmentSlots(office.idOffice, date))
        this.setState({ busy: false })
    }

    render() {
        const { appointmentslots, slot, date } = this.props
        const { busy } = this.state
        const appointments = appointmentslots && sortAlpha(appointmentslots, 'label').map(((s: AppointmentSlot) => ({ data: s, label: s.label }) ))
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: !!this.state.loading }) &&
                    <div className="container-quotationappointmentslots">
                        <Container>
                            <Row>
                                <Col xs={12} md={5} lg={4} className="d-flex justify-content-center">
                                    <DayPicker localeUtils={MomentLocaleUtils} locale="es" disabledDays={{ before: moment().add(24, 'hour').toDate() }} onDayClick={this.loadAppointmentSlot} selectedDays={moment(date).toDate()} />
                                </Col>
                                <Col xs={12} md={7} lg={8}>
                                    <Busy busy={busy}>
                                        <>
                                            <Col xs={12}>
                                                {appointments &&
                                                    <ColumnRadio selected={slot ? slot.label: null} options={appointments} size={2} onClick={this.clickAppointmentSlot} />
                                                }
                                            </Col>
                                            {/* <Row>
                                                {appointmentslots && sortAlpha(appointmentslots, 'label').map(((s: AppointmentSlot) => (
                                                    <Col xs={6} sm={4} md={3} lg={2} key={s.idAppointmentslot} className="mt-2">
                                                        <Fade>
                                                            <CardButton selected={slot ? slot.idAppointmentslot === s.idAppointmentslot : undefined} busy={loading === s.idAppointmentslot} name={s.label} onClick={this.clickAppointmentSlot(s)} />
                                                        </Fade>
                                                    </Col>
                                                )))}
                                            </Row> */}
                                        </>
                                    </Busy>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationAppointmentSlotsInjectedProps>((state: ReduxState) => ({ 
    appointmentslots: state.quotation.appointmentslots,
    office: state.quotation.selection.office,
    slot: state.quotation.selection.appointmentslot,
    date: state.quotation.selection.date
}), dispatchMap)(withGoogleAnalytics(QuotationAppointmentSlots))