
// ACTION TYPES DECLARED AT USER ACTION CONSTANTS
import { ReduxAction } from '#interfaces/ReduxActions';
import {SeoContentsState} from "#interfaces/ReduxState";

// REDUCER FUNCTION
export default function seocontents(
  state: SeoContentsState = {},
  action: ReduxAction<SeoContentsState>
) {
  switch (action.type) {
    default:
      return state;
  }
}
