import './style.scss'
import React from 'react'
import { Row, Col, Fade, Container } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Line, QuotationPageProps, Brand } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { getModels, setSelectedLine, setSelectedAppointmentOnly } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha, createQuotationLink } from '#helpers/utils'
import CardButton from '#components/CardButton'
import { QuotationPageContext } from '#screens/Quotation'
import GenericPartCartCreation from '#containers/GenericPartCartCreation'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import { GAEVENT_CATS } from '#constants'

interface QuoationLinesInjectedProps {
    lines?: Line[],
    line?: Line,
    brand?: Brand
}


class QuoationLines extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuoationLinesInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.analytics.gtag('event', 'view_item_list', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: 'lines' })
    }


    lineClick = (line: Line) => async () => {
        const { dispatch, onFinish, analytics: { gtag } } = this.props
        this.setState({ loading: line.idLine })

        gtag('event', 'select_item_line', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: line.name })

        dispatch(setSelectedLine(line))
        await dispatch(getModels(line.idLine))
        dispatch(setSelectedAppointmentOnly(false))
        onFinish()
    }

    render() {
        const { lines, line, brand } = this.props
        const { loading } = this.state
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: !!this.state.loading }) &&
                    <div className="container-quotationlines">
                        <Container>
                            <Busy busy={!lines}>
                                <>
                                    <Row>
                                        {lines && sortAlpha(lines, 'name').map((l => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={l.idLine} className="mt-3">
                                                <Fade>
                                                    <CardButton image_alt={l.image_alt} image={l.photo} selected={line.idLine === l.idLine} busy={loading === l.idLine} name={l.name} href={createQuotationLink([brand, l])} onClick={this.lineClick(l)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                        <Col xs={12} sm={6} md={4} lg={3} className="mt-3">
                                            <Fade>
                                                <GenericPartCartCreation />
                                            </Fade>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xs={12} className="d-flex justify-content-center mt-4">
                                            <GenericPartCartCreation />
                                        </Col>
                                    </Row> */}
                                </>
                            </Busy>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuoationLinesInjectedProps>((state: ReduxState) => ({ 
    lines: state.quotation.lines,
    line: state.quotation.selection.line || {},
    brand: state.quotation.selection.brand
}), dispatchMap)(withGoogleAnalytics(QuoationLines))