import _ from 'lodash';
import { History } from "history";
import Sucursal from '#interfaces/Sucursal';
import { ROUTES } from '#constants';
import { Product, Part, Model } from '#interfaces/Quotation';
import { GeoPosition } from '#interfaces/ReduxState';

declare var _initialAnitmation: any

export const hideInitialLoader = (time: number = 1000) => {
  const loader = document.querySelector('#loader') as HTMLElement;
  const animation = document.querySelector('#lottie') as HTMLElement;
  animation.style.transform = 'scale(0, 0)';
  loader.style.opacity = '0';
  setTimeout(() => { 
    loader.style.display = 'none'
    _initialAnitmation.stop();
  }, time);
}

export function sleep(time: number = 0) {
    return new Promise<void>((resolve, reject) => {
        setTimeout(() => resolve(), time);
    })
}

export const passwordContainsLength = (length: number) => (password: string): boolean => {
    return (password ? password.length >= length : false)
}

export const passwordContainsUppercase = (length: number) => (password: string): boolean => {
    return (password ? password.replace(/[^A-Z]/g, "").length >= length : false)
}

export const passwordContainsLowercase = (length: number) => (password: string): boolean => {
    return (password ? password.replace(/[^a-z]/g, "").length >= length : false)
}

export const passwordContainsNumber = (length: number) => (password: string): boolean => {
    return (password ? password.replace(/[^0-9]/g, "").length >= length : false)
}

export const passwordContainsSpecial = (length: number) => (password: string): boolean => {
    return (password ? password.replace(/[a-zA-Z0-9]/ig, "").length >= length : false)
}

export const matchRoutes = (routes: string[], matcher: History): boolean => {
    return !!routes.find(route => route === matcher.location.pathname)
}


export const fullOffuscatedPhone = (partialPhone: string = '', size: number = 10): string => {
    if(partialPhone && partialPhone.length){
        while(partialPhone.length < size) {
            partialPhone = "*" + partialPhone;
        }
        return partialPhone; 
    }else{
        return '**'
    }
    
}

export const dataAverage = (dataset: any[], propertypath: string = ''): number => {
    if (!_.isEmpty(dataset)) {
        return dataset.reduce((acum, entry, index) => {
            const current = acum + Number(_.get(entry, propertypath))
            if (index === dataset.length - 1) {
                return current / dataset.length
            }
            return current;
        }, 0)
    }
    return 0;
}

export const deg2rad = (deg: number) => {
  return deg * (Math.PI/180)
}

export const getDistanceBetweenPoints = (position1: { latitude: number,longitude: number }, position2: { latitude: number, longitude: number }) => {
    const lat1 = position1.latitude
    const lat2 = position2.latitude
    const lon1 = position1.longitude
    const lon2 = position2.longitude
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2-lat1);  // deg2rad below
    const dLon = deg2rad(lon2-lon1); 
    const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c * 1000; // Distance in meters
    return d;
}

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const sucursalCercana = (position: { latitude: number, longitude: number}, offices: Partial<Sucursal>[]): Partial<Sucursal> | undefined => {
    if (offices) {
        const sucursales_distancia = offices.map((suc) => ({ ...suc, distance: getDistanceBetweenPoints(suc.location, position) }))
        sucursales_distancia.sort((suc1, suc2) => suc1.distance < suc2.distance ? 1 : -1)
        return sucursales_distancia.pop();
    } 
}

export const sucursalAleatoria = (offices: Partial<Sucursal>[]): Partial<Sucursal> | undefined => {
    if (offices) {
        const indice = getRandomInt(0, offices.length)
        return offices[indice]
    }
}

export const sortAlpha = <T=any>(arr: Array<T>, prop: string): Array<T> => {
    return arr ? arr.sort((a, b) => {
        if(a[prop] < b[prop]) { return -1; }
        if(a[prop] > b[prop]) { return 1; }
        return 0;
    }) : null
}

export const parseProduct = (product: Product, part?: Part): { name: string, subname: string } => {
    const parsed = { name: product.name || '', subname: '' }
    if (product) {
        const usepart = product.part || part
        if (product.variation) {
            parsed.name = `${product.variation.name} ${product.variation.value || ''}`.trim()
        }
        if (usepart) {
            parsed.subname = usepart.name
        }
    }
    return parsed
}

export const parseModel = (model: Model): { name: string, subname: string } => {
    if (model) {
        const parts = model.name.split(' ')
        return { name: parts.slice(0,2).join(' '), subname: parts.slice(3).join(' ') }
    }
    return null
}

export const getDirections = (place_id: string, name: string): string => {
    // if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    //     return `http://maps.apple.com/?ll=${q}`
    // }
    return `https://maps.google.com/maps/dir/?api=1&destination=${name}&destination_place_id=${place_id}`
}

export interface ArrayDistanceSorted {
    distance?: number
}

export const sortByDistance = <T=any>(data: Array<T>, point: GeoPosition, getter: (value:T) => GeoPosition): Array<T & ArrayDistanceSorted> => {
    if (data) {
        const withdistance =  data.map(s => ({...s, distance: getDistanceBetweenPoints(getter(s), point)}))
        return withdistance.sort((a, b) => a.distance > b.distance ? 1 : -1)
    }
    return null
}

export const decompose = (rootpath: string, url: string): string[] => {
    if (rootpath && url) {
        const clean = url.replace(rootpath, '')
        const parts = clean.split('/')
        if (parts[0] === "") {
            parts.splice(0, 1)
        }
        return parts
    }
    return []
}

export const imageUrl = (path): string => {
    return `${process.env.REACT_APP_PUBLIC_URL}images/${path}`
}

export const formatCurrency = (amount: number, currency: string, locale?: string): string => {
    if (amount) {
        return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount)
    }
}

export const formatNumber = (amount: number, locale?: string): string => {
    if (amount) {
        return new Intl.NumberFormat(locale).format(amount)
    }
    return ''
}

export const createSlug = (ob: { slug?:string }): string => {
    if (ob && ob.slug) {
        return `${ob.slug.toLowerCase()}/`
    }
    return 'e/'
}

export const createQuotationLink = (ob: { slug?: string}[]): string => {
    return ob.reduce<string>((acc, o) => `${acc}${createSlug(o)}`, `${ROUTES.QUOTE}/`)
}

export const getNewCurrentPosition = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(position => {
      resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
    }, (positionError) => {
      console.error("could'nt get position", positionError.message);
      reject(positionError);
    })
  })
}
