
import _ from 'lodash';
import QueryString from 'query-string';



export interface HTTPRequestInit extends RequestInit {
    params?: {[key:string]: string}
}

/** Cliente HTTP Para consumir servicios externos (basado en fetch) */
abstract class HTTPClient<T> {
   
    host: string;
    defaults: RequestInit;
    beforeFilters: Array<Function>;
    afterFilters: Array<Function>;
    queue: Array<any>;
    putInQueue: boolean;
    token: T;
    onsession: (session: any) => any

    
    /**
    * @param {string} [host] Host o dirección web del servicio. Si no es definida los valores apuntan a una URL relativa
    * @param {object} [defaults] Objeto configurador de "fetch" con valores por defecto
    */
    constructor(host: string, defaults?: RequestInit ) {
        this.host = host || ''
        this.defaults = defaults || {}
        this.beforeFilters = []
        this.afterFilters = []
        this.queue = []
        this.putInQueue = false
    }

    protected abstract async requestSession(credentials: any): Promise<T>;
    protected abstract async renegotiateSession(): Promise<T>;
    protected abstract async shouldRenegotiate(response: Response | Error): Promise<boolean>;

    onSessionChange(fn: (session: T) => any) {
        this.onsession = fn
    }

    async endSession() {
        this.setToken(null)
    }

    async beginSession(credentials) {
        this.setToken(await this.requestSession(credentials))
        return this.token;
    }

    /**
    * Adds an event listener callback to any response that ends in certain status code
    * @param {Number} code Status code
    * @param {Function} callback to be called when that status code triggers
    */
    on(code: number, callback: Function) {
        this.addAfter((response: Response) => {
            if (response.status === code) {
                callback()
            }
            return response
        })
    }

    /**
    * Agrega nuevos valores de defecto a la configuración de la instancia
    * @param {object} values
    */
    addDefaults(values: Object) {
        this.defaults = _.merge(this.defaults || {}, values)
    }

    /**
    * Agrega el encabezado "Authorization" a los valores por defecto del cliente
    * @param {Object} value
    */
    setToken(session: T) {
        this.token = session
        if (this.onsession) {
            this.onsession(session)
        }
    }

    getToken(): T {
        return this.token
    }

    /**
    * Agrega una funcion al arreglo de filtros a ejecutarse antes de mandar una petición
    * El objeto a filtrar es el objeto "config" para Fetch
    * @param {Function}
    */
    addBefore(execution: Function){
        this.beforeFilters.push(execution)
    }
    /**
    * Agrega una funcion al arreglo de filtros a ejecutarse despues de mandar una petición
    * El objeto a filtrar es el objeto "response" de Fetch. El resultado del filtrado será
    * inyectado a la cadena de respuestas "then" de la promesa que regresan las peticiones.
    * @param {Function}
    */
    addAfter(execution: Function){
        this.afterFilters.push(execution)
    }
    /**
    * Ejecuta una petición GET
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    get(uri: string, configs?: HTTPRequestInit | null) {
        return this.request('GET', uri, null, configs)
    }
    /**
    * Ejecuta una petición POST
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {Array<any> | Object | FormData} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    post(uri: string, body: Array<any> | Object | FormData, configs?: HTTPRequestInit | null) {
        return this.request('POST', uri, body, configs)
    }
    /**
    * Ejecuta una petición PATCH
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {Array<any> | Object | FormData} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    patch(uri: string, body: Array<any> | Object | FormData, configs?: HTTPRequestInit | null) {
        return this.request('PATCH', uri, body, configs)
    }
    /**
    * Ejecuta una petición PUT
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {string} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    put(uri: string, body: Array<any> | Object | FormData, configs?: HTTPRequestInit | null) {
        return this.request('PUT', uri, body, configs)
    }
    /**
    * Ejecuta una petición DELETE
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    delete(uri: string, configs?: HTTPRequestInit | null) {
        return this.request('DELETE', uri, null, configs)
    }

    /** Ejecuta los filtros sobre el respectivo valor */
    filters(filters: Array<Function>, value: any) {
        let v = value
        for(const f of filters) {
            v = f(v)
        }
        return v
    }

    /**
    * Prepara el cuerpo de la petición con base al encabezado content-type
    * @param {Object} config
    * @return {Object}
    */
    parseBodyByContent(config: any) {
        if(config.body && !_.isString(config.body)) {
            const type = config.headers ? config.headers['Content-Type'] : ''
            //JSON
            if (type && type.includes('json')) {
                config.body = JSON.stringify(config.body)
            } else if (type && type.includes('x-www-form-urlencoded')) {
                config.body = QueryString.stringify(config.body)
            } else if (type && type.includes('form-data') && !(config.body instanceof FormData)){
                const formData = new FormData()
                for (var k in config.body) {
                    formData.append(k, _.isObject(config.body[k]) ? JSON.stringify(config.body[k]): config.body[k]);
                }
                config.body = formData
                delete config.headers['Content-Type']
            }
        }
        return config
    }

    resolveQueue() {
        if (this.queue.length) {
            this.queue.forEach(q => {
                const { method, uri, body, configs } = q.request
                this.request(method, uri, body, configs, false).then((response) => {
                    q.resolve(response)
                }).catch(error => {
                    q.reject(error)
                })
            })
        }
        this.putInQueue = false
        this.queue = []
    }

    prepareHeaders(headers: HeadersInit): HeadersInit {
        return headers
    }

    prepareBody(body: any) {
        return body
    }

    buildQueryParams(params: {[key:string]: string}): string {
        if (params && Object.keys(params).length > 0){
            return `?${QueryString.stringify(params)}`
        }
        return ''
    }

    /**
    * Ejecuta una petición HTTP a un recurso
    * @param {string} method Método HTTP
    * @param {string} uri URI del endpoint que se desea invocar
    * @param {string} body Cuerpo de la petición
    * @param {object} [configs] Configuraciones de la función "fetch"
    * @return {Promise} Promesa con el resultado de la petición
    */
    request(method: string, uri: string, body: Array<any> | Object | FormData | null, configs?: HTTPRequestInit | null, retry = true): Promise<Response> {
        return new Promise((resolve, reject) => {

            //Si se está en proceso de renegociación la petición se guarda para lanzarse hasta que termine
            //el proceso
            if (retry && this.putInQueue) {
                this.queue.push({ resolve, reject, request: { method, uri, body, configs }})
                return;
            }

            //Proceso de la petición
            const url = `${this.host}${uri}${this.buildQueryParams(configs ? configs.params : null)}`
            const configHeaders = configs ? configs.headers as any : {}
            const headers = this.prepareHeaders({ ...this.defaults.headers, ...configHeaders })
            body = this.prepareBody(body)
            let config = {...this.defaults, method, body, ...configs, headers: headers }
            config = this.filters(this.beforeFilters, config)
            config = this.parseBodyByContent(config)
            fetch(url, config as RequestInit).then(async (response) => {
                const should = await this.shouldRenegotiate(response)
                if (should && retry) {
                    this.queue.push({ resolve, reject, request: { method, uri, body, configs }})
                    try {
                        if (!this.putInQueue) {
                            this.putInQueue = true
                            const session = await this.renegotiateSession()
                            this.setToken(session);
                            this.resolveQueue()
                        }
                    } catch(e) {
                        this.resolveQueue()
                        console.error(e)
                    }
                    return;
                }
    
                response = this.filters(this.afterFilters, response);
                if (response.ok) {
                    resolve(response)
                    return;
                }
                reject({ response, error: await response.text() });
            }).catch(async (e) => {
                const should = await this.shouldRenegotiate(e)
                if (should && retry) {
                    this.queue.push({ resolve, reject, request: { method, uri, body, configs }})
                    try {
                        if (!this.putInQueue) {
                            this.putInQueue = true
                            const session = await this.renegotiateSession()
                            this.setToken(session);
                            this.resolveQueue()
                        }
                    } catch(e) {
                        this.resolveQueue()
                    }
                    return;
                }
                reject(e)
            })


        })
        
    }
}

export default HTTPClient
