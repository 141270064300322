import update from 'immutability-helper';
import { UserState } from '#interfaces/ReduxState';

// ACTION TYPES DECLARED AT USER ACTION CONSTANTS
import { TYPE_SET_USER_POSITION, TYPE_SET_SESSION } from '#actions/user';
import { ReduxAction } from '#interfaces/ReduxActions';


// REDUCER FUNCTION
export default function user ( state: UserState = {}, action: ReduxAction<UserState> )
{
	switch ( action.type )
	{
		case TYPE_SET_SESSION:
			return update(state, { $merge: { session: action.value.session } })
		case TYPE_SET_USER_POSITION:
			return update( state, { $merge: { position: action.value.position }} );
		default:
			return state;
	}
}