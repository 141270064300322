import React from 'react'
import { Spinner, Fade } from 'reactstrap';
import './style.scss'

export interface BusyProps {
    busy: boolean;
    color?: string;
    children: React.ReactNode
}

const Busy = (props: BusyProps) => (
    <div className="component-busy">
        {props.busy && <Spinner className="spinner" type="grow" color={props.color} />}
        <Fade in={!props.busy}>
            {props.children}
        </Fade>
    </div>
)

export default Busy;