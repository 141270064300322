import React from 'react'

import './style.scss'
import { connect } from 'react-redux'
import ReduxState from '#interfaces/ReduxState'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { sucursalCercana, sucursalAleatoria } from '#helpers/utils'
import SucursalComponent from '#components/Sucursal'
import { Office } from '#interfaces/Quotation'

interface SucursalesWidgetInjectedProps {
    position: { latitude: number, longitude: number }
    offices?: Office[]
}

class SucursalesWidget extends React.PureComponent<SucursalesWidgetInjectedProps & ThunkDispatchProp> {
    
    render() {
        const { position, offices } = this.props
        const sucursal = position ? sucursalCercana(position, offices) : sucursalAleatoria(offices)
        return (
            <div className="container-sucursaleswidget">
                <SucursalComponent sucursal={sucursal} link />
            </div>
        )
    }
}

export default connect<SucursalesWidgetInjectedProps>((state: ReduxState) => ({ 
    position: state.user.position,
    offices: state.quotation.offices
}), dispatchMap)(SucursalesWidget)
