import React from 'react'
import { Row, Col, Button, Spinner } from 'reactstrap'

interface PaymentPaypalProps {
    onSubmit: () => void 
    busy?: boolean
}


const PaymentPaypal = (props: PaymentPaypalProps) => {

    return (
        <>
            <Row>
                <Col xs={12} className="mb-3">
                    <h4>Pago usando Paypal</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} md={8}>
                    <p>Para continuar con tu pago te vamos a redirigir a la página de Paypal. Una vez terminado tu pago, automaticamente regresarás a 
                        esta página para confirmar que tu pago se realizó con éxito.</p>
                </Col>
                <Col xs={12} md={4} className="d-flex align-items-center justify-content-center mt-3 mt-md-0">
                    {props.busy ? <Spinner color="alt-primary" /> : <Button block onClick={props.onSubmit} color="primary">Entendido</Button>}
                </Col>
            </Row>
        </>
    )
}

export default PaymentPaypal
