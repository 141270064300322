import React from 'react'
import CurrencyFormat from 'react-currency-format';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import './style.scss';

interface CurrencyProps {
    value: number
    currency?: string
    symbol?: string
    clean?: boolean
    fixed?: number
}
const Currency = (props: CurrencyProps & InjectedIntlProps) => {
    const prefix = props.currency ? props.intl.formatMessage({ id: `format.symbol_prefix_${props.currency.toLowerCase()}`, defaultMessage: props.symbol || '$' }) : '$'
    const sufix = (!props.clean && props.currency) ? props.intl.formatMessage({ id: `format.symbol_sufix_${props.currency.toLowerCase()}`, defaultMessage: props.currency.toUpperCase() }): ''
    return (
        <CurrencyFormat
            displayType="text"
            value={props.value}
            thousandSeparator={props.intl.formatMessage({ id: 'format.thousand_separator', defaultMessage: ',' })}
            decimalSeparator={props.intl.formatMessage({ id: 'format.decimal_separator', defaultMessage: '.' })}
            decimalScale={props.fixed || 2}
            fixedDecimalScale={true}
            prefix={prefix}
            sufix={sufix}
            renderText={(value) => <span title={`${value} ${sufix}`.trim()} className="currency">{`${value} ${sufix}`.trim()}</span>}
        />
    )
}

export default injectIntl(Currency)
