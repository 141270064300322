import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import ReduxState from "./ReduxState";

export interface ReduxAction<A> extends Action<string> {
    value: A
}

export interface ThunkPromiseAction<R,A = any> extends ThunkAction<Promise<R>, ReduxState, any, ReduxAction<A>> {}

export interface ThunkDispatchProp {
    dispatch?: (<T = any>(action: ThunkPromiseAction<T> | ReduxAction<T>) => Promise<T> | T) 
}

export interface ReduxDispatch<S> extends ThunkDispatch<ReduxState, any, ReduxAction<S>> {}

export const dispatchMap = (dispatch: ThunkDispatch<ReduxState, any, ReduxAction<any>>) => ({
    dispatch: <T = any>(arg: ThunkPromiseAction<T>) => dispatch(arg)
});

export type StateGetter = () => ReduxState
