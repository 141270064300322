import './style.scss'
import _ from 'lodash'
import React from 'react'
import { Row, Col, Fade, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Product, QuotationPageProps, Model, Part, findPriceId } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { setSelectedProduct, setSelectedPart, getProductsAndSelect } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha, parseProduct, parseModel } from '#helpers/utils'
import CardButton from '#components/CardButton'
import { QuotationPageContext } from '#screens/Quotation'
import { DIAGNOSTIC_SERVICE_ID, GAEVENT_CATS } from '#constants'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'

interface QuotationProductsInjectedProps {
    products?: Product[]
    model?: Model
    part?: Part
    product?: Product
    parts?: Part[]
}


class QuotationProducts extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuotationProductsInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null,
        open: true
    }

    componentDidMount() {
        const { part, onFinish } = this.props
        if (!part) {
            onFinish()
        }
        window.scrollTo(0, 0)
        this.props.analytics.gtag('event', 'view_item_list', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: 'products' })
    }

    // async componentDidUpdate() {
    //     const { part, products, onFinish, dispatch } = this.props
    //     if (part && part.idPart === DIAGNOSTIC_ID && products && products.length === 1) {
    //         await dispatch(setSelectedProduct(products.pop()))
    //         onFinish()
    //     }
    // }

    productClick = (product: Product) => async () => {
        const { dispatch, onFinish } = this.props
        this.setState({ loading: product.idProduct })
        await dispatch(setSelectedProduct(product))
        this.props.analytics.gtag('event', 'select_item_product', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: product.name })
        onFinish()
    }

    skipToSchedule = async () => {
        const { dispatch, onFinish, model, parts } = this.props
        this.setState({ open: false })
        const diagnostic = parts.find(p => p.idPart === DIAGNOSTIC_SERVICE_ID)
        await dispatch(setSelectedPart(diagnostic))
        await dispatch(getProductsAndSelect(model.idModel, DIAGNOSTIC_SERVICE_ID))
        onFinish()
    }

    goBack = () => {
        const { onBack } = this.props
        const { dispatch } = this.props
        if(onBack) { onBack() }
        dispatch(setSelectedPart(null))
    }
    

    render() {
        const { products, model, part, product } = this.props
        const { loading, open } = this.state
        const modelparsed = parseModel(model)
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: !!this.state.loading }) && modelparsed &&
                    <div className="container-quotationproducts">
                        <Container>
                            <Busy busy={!products}>
                                <>
                                    <Row>
                                        {products && !_.isEmpty(products) && sortAlpha(products, 'name').map(((p: Product) => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={p.idProduct} className="mt-1">
                                                <Fade>
                                                    <CardButton image_alt={p.image_alt} image={p.photo} busy={loading === p.idProduct} price={findPriceId(p.prices, 1)} selected={product && p.idProduct === product.idProduct} name={parseProduct(p, part).name} subname={parseProduct(p, part).subname} onClick={this.productClick(p)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                    </Row>
                                </>
                            </Busy>
                            <Modal isOpen={open && part && products && _.isEmpty(products)} toggle={this.goBack}>
                                <ModalHeader toggle={this.goBack}>Productos no encontrados</ModalHeader>
                                <ModalBody>
                                        Por el momento no tenemos <b>{part ? part.name.toLowerCase().trim() : ''}</b>  disponibles para <b>{modelparsed.name.toLowerCase()}{' '}</b>
                                    {modelparsed.subname && `(${modelparsed.subname})`},
                                    te invitamos a solicitar una valoración para ver si podemos conseguir tu pieza.
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="muted" onClick={this.goBack}>Regresar</Button>{' '}
                                    <Button color="primary" onClick={this.skipToSchedule}>Solicitar valoración</Button>
                                </ModalFooter>
                            </Modal>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationProductsInjectedProps>((state: ReduxState) => ({ 
    products: state.quotation.products,
    model: state.quotation.selection.model,
    part: state.quotation.selection.part,
    product: state.quotation.selection.product,
    parts: state.quotation.parts
}), dispatchMap)(withGoogleAnalytics(QuotationProducts))