import './style.scss'
import React, { useMemo } from 'react'
import Img from '#components/Image'

interface ButtonWhatsappProps {
    line: string
}

export default function ButtonWhatsapp(props: ButtonWhatsappProps) {

    const line = useMemo(() => props.line ? props.line.replace(/[^0-9]+/g, "") : '', [props.line])

    return (
        <a target="_blank" rel="noopener noreferrer" href={`https://wa.me/${line}`} className="component-buttonwhatsapp">
            <Img src="whatsapp.png" />
        </a>
    )
}