import React from 'react'
import { Row, Col, Button, Spinner } from 'reactstrap'

interface PaymentCashProps {
    onSubmit: () => void 
    busy?: boolean
}


const PaymentCash = (props: PaymentCashProps) => {

    return (
        <>
            <Row>
                <Col xs={12} className="mb-3">
                    <h4>Pago con efectivo</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} md={8}>
                    <p>Deberás cubrir el cargo total al finalizar tu servicio usando efectivo.</p>
                </Col>
                <Col xs={12} md={4} className="d-flex justify-content-center align-items-center mt-3 mt-md-0">
                    {props.busy ? <Spinner color="alt-primary" /> : <Button block onClick={props.onSubmit} color="primary">Entendido</Button>}
                </Col>
            </Row>
        </>
    )
}

export default PaymentCash
