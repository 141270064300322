import './style.scss'
import React, { useMemo, useRef } from 'react'
import { useWindowSize } from '#helpers/hooks'

interface StepBar {
    title: string 
    onClick?: () => void
}

interface StepperBarProps {
    activeStep: number
    steps: StepBar[]
    disableClicks?: boolean
}

const StepperBar = (props: StepperBarProps) => {
    const bar = useRef<HTMLDivElement>()
    const wsize = useWindowSize()
    const stepWidth = useMemo(() => {
        if (props.steps.length && bar.current) {
            //wsize is used so the tslint doesnt complain. wsize is monitored for changes to recalculate the bar width
            return (wsize.width-wsize.width) + bar.current.offsetWidth / props.steps.length
        }
        return 0
    }, [props, bar, wsize])

    return(
        <div className={`component-stepperbar ${props.disableClicks ? 'disable-clicks' : ''}`}>
            <div ref={bar} className="bar-container">
                <div style={{ width: `${stepWidth * (props.activeStep + 1)}px` }} className="bar" />
            </div>
            <div className="d-none d-md-flex step-blocks">
                {props.steps && props.steps.map((s,i) => (
                    <div onClick={!props.disableClicks ? s.onClick : undefined} key={s.title} className={`step ${i === props.activeStep ? 'active' : ''}`}>
                        {s.title}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StepperBar
