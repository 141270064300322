import './style.scss'
import React from 'react'
import { Row, Col, Fade, Container } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Brand, QuotationPageProps } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { getLines, setSelectedBrand, setSelectedAppointmentOnly } from '#actions/quotation'
import Busy from '#components/Busy'
import CardButton from '#components/CardButton'
import { sortAlpha, createQuotationLink } from '#helpers/utils'
import { QuotationPageContext } from '#screens/Quotation'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import { GAEVENT_CATS } from '#constants'

interface QuotationBrandsInjectedProps {
    brands?: Brand[]
    brand?: Brand
}


class QuotationBrands extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuotationBrandsInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.analytics.gtag('event', 'view_item_list', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: 'brands' })
    }

    brandClick = (brand: Brand) => async () => {
        const { dispatch, onFinish, analytics: { gtag } } = this.props
        this.setState({ loading: brand.idBrand })
        
        gtag('event', 'select_item_brand', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: brand.name })

        dispatch(setSelectedBrand(brand))
        dispatch(setSelectedAppointmentOnly(false))
        await dispatch(getLines(brand.idBrand))
        onFinish()
    }

    render() {
        const { brands, brand } = this.props
        const { loading } = this.state
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: !!this.state.loading }) &&
                    <div className="container-quotationbrands">
                        <Container>
                            <Busy busy={!brands}>
                                <>
                                    <Row>
                                        {brands && sortAlpha(brands, 'name').map((b => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={b.idBrand} className="mt-2">
                                                <Fade>
                                                    <CardButton image_alt={b.image_alt} image={b.photo} selected={b.idBrand === brand.idBrand} busy={loading === b.idBrand} href={createQuotationLink([b])} onClick={this.brandClick(b)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                    </Row>
                                </>
                            </Busy>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationBrandsInjectedProps>((state: ReduxState) => ({ 
    brands: state.quotation.brands,
    brand: state.quotation.selection.brand || {}
}), dispatchMap)(withGoogleAnalytics(QuotationBrands))
