import colors from './colors.scss';
import variables from './variables.scss';

export const Variables = variables


export const Palettes = {
    /** Main color palette */
    Main: {
        primary: colors.colorPrimary,
        secondary: colors.colorSecondary,
        highlight: colors.colorHighlight
    },
    /** Alternative or Secondary color palette */
    Alt: {
        primary: colors.colorAltPrimary,
        secondary: colors.colorAltSecondary,
        highlight: colors.colorAltHighlight
    },
    /** Monochromatic Palette */
    Mono: {
        white: colors.colorWhite,
        black: colors.colorBlack,
        gray: colors.colorGray
    },
    /** Complementary Colors Palette */
    Complementary: {
        color1: colors.colorComplementary1,
        color2: colors.colorComplementary2,
        color3: colors.colorComplementary3,
        color4: colors.colorComplementary4,
        color5: colors.colorComplementary5
    },
    /** Symbolic Colors Palette */
    Symbolic: {
        info: colors.colorInfo,
        warning: colors.colorWarning,
        danger: colors.colorDanger,
        success: colors.colorSuccess
    }
}