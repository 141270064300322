import React from 'react'
import { Row, Col, Button, Spinner } from 'reactstrap'

interface PaymentPhysicalTerminalProps {
    onSubmit: () => void 
    busy?: boolean
}


const PaymentPhysicalTerminal = (props: PaymentPhysicalTerminalProps) => {

    return (
        <>
            <Row>
                <Col xs={12} className="mb-3">
                    <h4>Pago con términal física</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} md={8}>
                    <p>Deberás cubrir el cargo total al finalizar tu servicio usando una tarjeta de crédito o debito a través de una términal física que nuestros técnicos llevarán a tu domicilio.</p>
                </Col>
                <Col xs={12} md={4} className="d-flex align-items-center justify-content-center mt-3 mt-md-0">
                    {props.busy ? <Spinner color="alt-primary" /> : <Button block onClick={props.onSubmit} color="primary">Entendido</Button>}
                </Col>
            </Row>
        </>
    )
}

export default PaymentPhysicalTerminal
