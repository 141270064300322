import React from 'react'
import _ from 'lodash'
import { ConektaAPI } from './interfaces';
import { IOInput, IOInputProps } from 'react-io-forms';

declare const Conekta: ConektaAPI
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;


export function ConektaCardInput({ validate, name, required, ...rest  }: Omit<IOInputProps, 'name'>) {
    return <IOInput {...rest} maxLength={16} required name="number" validate={_.flatten([Conekta.card.validateNumber, validate])} />
}

export function ConektaExpMonthInput({ validate, name, required, ...rest }: Omit<IOInputProps, 'name'>) {
    return <IOInput {...rest} maxLength={2} required name="exp_month"  validate={_.flatten([(v) => Conekta.card.validateExpirationDate(Number(v), 2025), validate])} />
}

export function ConektaExpYearInput({ validate, name, required, ...rest }: Omit<IOInputProps, 'name'>) {
    return <IOInput {...rest} maxLength={4} required name="exp_year"  validate={_.flatten([(v) => Conekta.card.validateExpirationDate(1, v), validate])} />
}

export function ConektaCVCInput({ validate, name, required, ...rest }: Omit<IOInputProps, 'name'>) {
    return <IOInput {...rest} maxLength={4} type="password" required name="cvc" validate={_.flatten([Conekta.card.validateCVC, validate])} />
}

export function ConektaNameInput({ required, ...rest }: Omit<IOInputProps, 'name'>) {
    return <IOInput {...rest} required name="name"  />
}

