import './style.scss'
import _ from 'lodash'
import React from 'react'
import { Row, Col, Fade, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Part, Model, QuotationPageProps, Brand, Line } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { setSelectedPart, getProducts, getProductsAndSelect, setSelectedAppointmentOnly } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha, imageUrl, createQuotationLink } from '#helpers/utils'
import CardButton from '#components/CardButton'
import { TOP_PARTS_IDS, SYNTH_SERVICES, GAEVENT_CATS } from '#constants'
import { QuotationPageContext } from '#screens/Quotation'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'

interface QuoationPartsInjectedProps {
    parts?: Part[]
    model?: Model
    part?: Part
    brand?: Brand
    line?: Line
}


class QuoationParts extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuoationPartsInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null,
        modal: false,
        collapsed: true
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.analytics.gtag('event', 'view_item_list', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: 'parts' })
        
    }

    partClick = (part: Part) => async () => {
        const { dispatch, model, onFinish } = this.props
        this.setState({ loading: part.idPart })
        dispatch(setSelectedAppointmentOnly(false))
        await dispatch(getProducts(model.idModel, part.idPart))
        await dispatch(setSelectedPart(part))

        this.props.analytics.gtag('event', 'select_item_part', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: part.name })
        onFinish()
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    skipToSchedule = async () => {
        const { dispatch, onFinish, model } = this.props
        dispatch(setSelectedAppointmentOnly(false))
        await dispatch(setSelectedPart(SYNTH_SERVICES.DIAGNOSTIC))
        await dispatch(getProductsAndSelect(model.idModel, SYNTH_SERVICES.DIAGNOSTIC.idPart))
        onFinish()
    }

    showOthers = () => {
        this.setState({ collapsed: false })
        this.props.analytics.gtag('event', 'view_item_full_list', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: 'parts' })
    }

    render() {
        const { parts, brand, line, model } = this.props
        // parts.splice(parts.findIndex(p => p.idPart === SYNTH_SERVICES.DIAGNOSTIC.idPart), 1)
        const { loading, modal, collapsed } = this.state
        const topparts = TOP_PARTS_IDS.map(top => parts.find(p => p.idPart === top)).filter(p => !!p)
        const restparts = parts.filter(p => !TOP_PARTS_IDS.includes(p.idPart))
        const part = _.get(this.props, 'part') || {}
        const show = (part && !!restparts.find((p) => p.idPart === part.idPart)) || !collapsed

        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: !!this.state.loading }) &&
                    <div className="container-quotationparts">
                        <Container>
                            <Busy busy={!parts}>
                                <>
                                    <Row>
                                        {topparts && topparts.map(((p: Part) => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={p.idPart} className="mt-3">
                                                <Fade>
                                                    <CardButton image_alt={p.image_alt} image={p.photo} selected={p.idPart === part.idPart} busy={loading === p.idPart} name={p.name} href={createQuotationLink([brand, line, model, p])} onClick={this.partClick(p)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                        {!show && <Col xs={12} sm={6} md={4} lg={3} className="mt-3">
                                            <Fade>
                                                <CardButton busy={false} alt image={imageUrl('iconos/part_otros.png')} name={'Otros'} onClick={this.showOthers} />
                                            </Fade>
                                        </Col>}
                                        {show && restparts && sortAlpha(restparts, 'name').map(((p: Part) => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={p.idPart} className="mt-3">
                                                <Fade>
                                                    <CardButton image_alt={p.image_alt} image={p.photo} selected={p.idPart === part.idPart} busy={loading === p.idPart} name={p.name} href={createQuotationLink([brand, line, model, p])} onClick={this.partClick(p)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                        
                                    </Row>
                                </>
                            </Busy>
                        </Container>
                        <Modal isOpen={modal} toggle={this.toggle}>
                            <ModalHeader toggle={this.toggle}>Agendemos una cita</ModalHeader>
                            <ModalBody>
                                Si no tienes claro que tiene tu dispositivo, no te preocupes, traelo para una valoración. ¿Quieres agendar una cita?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.skipToSchedule}>Si, agendar</Button>{' '}
                                <Button color="muted" onClick={this.toggle}>Regresar</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuoationPartsInjectedProps>((state: ReduxState) => ({ 
    parts: state.quotation.parts,
    model: state.quotation.selection.model,
    part: state.quotation.selection.part,
    brand: state.quotation.selection.brand,
    line: state.quotation.selection.line
}), dispatchMap)(withGoogleAnalytics(QuoationParts))