import { GeneralState } from "#interfaces/ReduxState"
import { ActionCreator } from "redux";
import { ReduxAction } from "#interfaces/ReduxActions";
import APIClient from "#helpers/APIClient";
import { APIResponse } from "#interfaces/Quotation";

export const TYPE_SET_STATS = '@General/SET_STATS'

export const setStats:ActionCreator<ReduxAction<GeneralState>> = (stats: Object) => {
    return {
        type: TYPE_SET_STATS,
        value: { stats }
    }
}

export const getStats = () => async (dispatch): Promise<void> => {
    const response = await APIClient.get().post(`/stats/operation:get`, {})
    const body = await response.json() as APIResponse<Object>
    dispatch(setStats(body.data))
}