import React from 'react'

interface ImgProps {
    src: string
    alt?: string
    className?: string
    size?: number
}

const Img = (props: ImgProps) => {
  if (props.src && props.src.includes("http")) {
    return (
      <img style={props.size ? { width: `${props.size}px` } : undefined} className={`component-img ${props.className || ''}`.trim()} src={props.src} alt={props.alt} />
    )
  }
  return (
    <img style={props.size ? { width: `${props.size}px` } : undefined} className={`component-img ${props.className || ''}`.trim()} src={`${process.env.REACT_APP_PUBLIC_URL}images/${props.src}`} alt={props.alt} />
  )
}

export default Img
