class ActionBuffer {
    private _timeoutLength: number
    private _timeoutId: any

    static create(timeout: number) {
        return new ActionBuffer(timeout)
    }

    constructor(timeout: number = 700) {
        this._timeoutLength = timeout
    }

    execute(func: Function, ...params) {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId)
        }
        this._timeoutId = setTimeout(() => func(...params), this._timeoutLength)
    }

    promise<R>(func: (...args: any) => R, ...params: any): Promise<R> {
        return new Promise((resolve) => {
            if (this._timeoutId) {
                clearTimeout(this._timeoutId)
            }
            this._timeoutId = setTimeout(() => resolve(func(...params)), this._timeoutLength)
        })
    }
    
}

export default ActionBuffer