import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions';
// import _ from 'lodash';
import ReduxState from '#interfaces/ReduxState';
import { Row, Col, Fade, Container } from 'reactstrap';

import MiReparacionWidget from '#containers/MiReparacionWidget';
import SucursalesWidget from '#containers/SucursalesWidget';
import QuotationWidget from '#containers/QuotationWidget';
import { ROUTES } from '#constants';
import TitleBar from '#components/TitleBar';
import MaterialIcon from 'material-icons-react';
import PhotoSlider from '#components/PhotoSlider';
import { imageUrl } from '#helpers/utils';
import GeneralDataWidget from '#containers/GeneralDataWidget';

interface HomeProps{}

/**
 * This Login Screen Component implements the login screen
 * @type React.Component
 */
class Home extends React.Component<HomeProps & ThunkDispatchProp & RouteComponentProps<any>> {

    state = {
        photos: []
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            photos: [
                {
                    original: imageUrl('portada-1-1080x380.png'),
                    onClick: this.goto(ROUTES.QUOTE)
                },
                {
                    original: imageUrl('portada-2-1080x380.png'),
                    onClick: this.goto(ROUTES.QUOTE)
                },
                {
                    original: imageUrl('portada-3-1080x380.png'),
                    onClick: this.goto(ROUTES.QUOTE)
                },
                {
                    original: imageUrl('portada-4-1080x380.png'),
                    onClick: this.goto(ROUTES.QUOTE)
                }
            ]
        })
    }

    goToQuote = () => {
        const { history } = this.props
        history.push(ROUTES.QUOTE)
    }

    goToRepair = () => {
        const { history } = this.props
        history.push(ROUTES.MYREPAIR)
    } 
    
    goto = (route: string) => () => {
        this.props.history.push(route)
    }

    render () {
        return (
            <div className="screen screen-home">
                <Container>
                    <div className="grid-wrapper">
                        <Row>
                            <Col className="d-flex flex-column flex-grow-1 row-grow" xs={12}>
                                <PhotoSlider photos={this.state.photos} />
                            </Col>
                            <Col className="d-flex flex-column flex-grow-1 row-grow" xs={12}>
                                <TitleBar invert icon={<MaterialIcon icon="computer" />} title={'Cotiza tu reparación'} subtitle={'Selecciona la marca de tu dispositivo'} />
                            </Col>
                            <Col className="d-flex flex-column flex-grow-1 row-grow" xs={12}>
                                <div className="cotizar">
                                    <Fade>
                                        <QuotationWidget onBrand={this.goToQuote} />
                                    </Fade>
                                </div>
                            </Col>
                            <Col className="d-flex flex-column row-grow" xs={12}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="mireparacion">
                                            <Fade>
                                                <MiReparacionWidget onRepair={this.goToRepair} />
                                            </Fade>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="sucursales" >
                                            <Fade>
                                                <SucursalesWidget />
                                            </Fade>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="d-flex flex-column flex-grow-1 row-grow" xs={12}>
                                <Fade>
                                    <GeneralDataWidget />
                                </Fade>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect( ( state: ReduxState ) => ({}), dispatchMap)(withRouter(Home));
