
import React from 'react'
import _ from 'lodash'

export interface LatLng {
    /** Comparison function. */
    equals(other: LatLng):boolean;
    /** Returns the latitude in degrees. */
    lat(): number;
    /** Returns the longitude in degrees. */
    lng(): number;
    /** Converts to string representation. */
    toString(): string;
    /**
     * Returns a string of the form "lat,lng". We round the lat/lng values to 6
     * decimal places by default.
     */
    toUrlValue(precision?: number): string;
    /**
     * Converts to JSON representation. This function is intended to be used
     * via JSON.stringify.
     */
    toJSON(): LatLngLiteral;
}

export type AddressType = 'street_number' | 'route' | 'neighborhood' | 'political' | 'locality' | 'sublocality' | 
'sublocality_level_1' | 'sublocality_level_2' | 'administrative_area_level_2' | 'administrative_area_level_1' |
'country' | 'postal_code' | 'street_address'

export interface AddressComponent {
    long_name: string
    short_name: string 
    types: AddressType[]
}

export interface LatLngLiteral {
    lat: number, lng: number
}

export interface GeometryResult {
    location: LatLng
    location_type: GeocoderLocationType
    viewport: { northeast: LatLngLiteral, southwest: LatLngLiteral }
}

export interface GeocodeResult {
    address_components: AddressComponent[]
    formatted_address: string
    postcode_localities: AddressComponent[]
    geometry: GeometryResult
    place_id: string
    types: AddressType
}

enum GeocoderLocationType {
    APPROXIMATE = 'APPROXIMATE',
    GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
    RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
    ROOFTOP = 'ROOFTOP',
}

enum GeocoderStatus {
    ERROR = 'ERROR',
    INVALID_REQUEST = 'INVALID_REQUEST',
    OK = 'OK',
    OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
    REQUEST_DENIED = 'REQUEST_DENIED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    ZERO_RESULTS = 'ZERO_RESULTS',
}

export interface RequestResult {
    results: GeocodeResult[]
    status: GeocoderStatus
}

export interface LatLngBoundsLiteral {
    east: number;
    north: number;
    south: number;
    west: number;
}
export interface GeocoderComponentRestrictions {
    administrativeArea?: string;
    country?: string | string[];
    locality?: string;
    postalCode?: string;
    route?: string;
}

interface GeocoderRequest {
    address?: string;
    bounds?: LatLngBoundsLiteral;
    componentRestrictions?: GeocoderComponentRestrictions;
    location?: LatLng | LatLngLiteral;
    placeId?: string;
    region?: string;
}

class GoogleMapsAPI {
    private static APIURL = 'https://maps.googleapis.com/maps/api/js'
    static MAP_EMBED_URL = 'https://www.google.com/maps/embed/v1/place'

    public static google: any = null
    private static takeLatest: any = null
    private static _creating: Promise<any> = null
    private static _key: string = null

    
    static get(apikey: string): Promise<any> {
        if (!GoogleMapsAPI._creating) {

            if (!apikey) {
                return Promise.reject(new Error('GoogleMapsAPI.js:: Null or Empty Google API Key'))
            }
            if (GoogleMapsAPI.google) {
                return Promise.resolve(GoogleMapsAPI.google)
            }
            GoogleMapsAPI._key = apikey
            GoogleMapsAPI._creating = new Promise((resolve, reject) => {
                if (!GoogleMapsAPI.google) {
                    const script = document.createElement("script");
                    script.src = `${GoogleMapsAPI.APIURL}?key=${apikey}&libraries=places`
        
                    script.onload = () => {
                        GoogleMapsAPI.google = window['google']
                        resolve(GoogleMapsAPI.google)
                    };
                    script.onerror = (e) => {
                        reject(e)
                    }
                    document.body.appendChild(script);
                } else {
                    resolve(GoogleMapsAPI.google)
                }
            })
        }
        return GoogleMapsAPI._creating
    }

    
    static geocode(request: GeocoderRequest, takeLatest = false): Promise<GeocodeResult[]> {
        return new Promise((resolve, reject) => {
            if (GoogleMapsAPI.takeLatest) {
                window.clearTimeout(GoogleMapsAPI.takeLatest)
            }
            GoogleMapsAPI.takeLatest = setTimeout(() => {
                const geocoder = new GoogleMapsAPI.google.maps.Geocoder();
                geocoder.geocode(request, function(results: GeocodeResult[], status: GeocoderStatus) {
                    if (status === 'OK') {
                        resolve(results)
                    } else {
                        reject(status)
                    }
                })
            }, takeLatest ? 600 : 0)
        })
    }


    static extractResultByType(result: GeocodeResult[], type: AddressType): GeocodeResult {
        if (result) {
            return result.find((geo) => geo.types.includes(type))
        }
        return null
    }

    static extractAddressComponentByType(result: GeocodeResult, type: AddressType): AddressComponent {
        if (result) {
            return result.address_components.find((ac) => ac.types.includes(type))
        }
        return null
    }

    static extractAddressNameByTypes(result: GeocodeResult, types: AddressType[]): string {
        if (result) {
            const component = result.address_components.find((ac) => _.intersection(ac.types, types).length > 0)
            if (component) {
                return component.long_name
            }
        }
        return null
    }

    static extractLocation(result: GeocodeResult[]): LatLngLiteral {
        if (result && result.length) {
            const location = result[0].geometry.location
            return location.toJSON()
        }
        return { lat: null, lng: null }
    }

    static render(query: string, key: string, maps?: string) {
      if (maps) {
        return (
	  <a href={maps || ""} target="_blank" rel="noopener noreferrer" style={{ all: "unset", width: "100%", cursor: "pointer" }}>
            <iframe
                title={query}
                src={`${GoogleMapsAPI.MAP_EMBED_URL}?key=${key || GoogleMapsAPI._key}&q=${encodeURI(query)}`}
                frameBorder="0"
		style={{ border: "0", width: '100%', pointerEvents: "none", height: "100%" }}
                allowFullScreen
            />
	  </a>
        )
      } else {
	return (
            <iframe
                title={query}
                src={`${GoogleMapsAPI.MAP_EMBED_URL}?key=${key || GoogleMapsAPI._key}&q=${encodeURI(query)}`}
                frameBorder="0"
		style={{ border: "0", width: '100%' }}
                allowFullScreen
            />
	)
      }
    }
}

export default GoogleMapsAPI
