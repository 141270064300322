import React, { useCallback, useState } from 'react'
import ImageGallery from 'react-image-gallery'

interface PhotoSlide {
    original: string
    onClick: () => void
}

interface PhotoSliderProps {
    photos: PhotoSlide []
}

const PhotoSlider = (props: PhotoSliderProps) => {
    const [ slide, setSlide ] = useState(0)
    const onClick = useCallback(() => {
        props.photos[slide].onClick()
    }, [slide, props.photos])
    const onSlide = useCallback((slideIndex: number) => { setSlide(slideIndex) }, [])


    return (
        <div className="component-photoslider">
            {props.photos &&
                <ImageGallery
                    items={props.photos}
                    lazyLoad
                    showNav={false}
                    showThumbnails={false}
                    autoPlay
                    showPlayButton={false}
                    showFullscreenButton={false}
                    slideInterval={5000}
                    onClick={onClick}
                    onSlide={onSlide}
                />
            }
        </div>
    )
}


export default PhotoSlider
