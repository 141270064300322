import update from 'immutability-helper';
import { RepairState } from '#interfaces/ReduxState';

// ACTION TYPES DECLARED AT USER ACTION CONSTANTS
import { ReduxAction } from '#interfaces/ReduxActions';
import { TYPE_SET_REPAIR } from '#actions/repair';


// REDUCER FUNCTION
export default function repair ( state: RepairState = {}, action: ReduxAction<RepairState> )
{
	switch ( action.type )
	{
		case TYPE_SET_REPAIR:
			return update(state, { $set: action.value  })
		default:
			return state;
	}
}