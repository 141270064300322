// import { sleep } from "#helpers/utils";
import { ActionCreator } from "redux";
import { UserState } from "#interfaces/ReduxState";
import { ReduxAction } from "#interfaces/ReduxActions";
import { APISession } from "#helpers/APIClient";

export const TYPE_SET_USER_POSITION = '@User/SET_POSITION'
export const TYPE_SET_SESSION = '@User/SET_SESSION'

export const setUserPosition:ActionCreator<ReduxAction<UserState>> = (latitude: number, longitude: number) => {
    return {
        type: TYPE_SET_USER_POSITION,
        value: { position: { latitude, longitude } }
    }
}

export const setSession:ActionCreator<ReduxAction<UserState>> = (session: APISession) => {
    return {
        type: TYPE_SET_SESSION,
        value: { session }
    }
}
