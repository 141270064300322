import './style.scss'
import React from 'react'
import { Row, Col } from 'reactstrap'
import { PaymentState } from '#interfaces/ReduxState'
import Currency from '#components/Currency'
import Barcode from 'react-barcode'
import { Cart, Part, Product } from '#interfaces/Quotation'

interface PaymentCardProps {
    cart: Cart
    payment: PaymentState
    appointment: { barcode?: string, folio?: string }
    part: Part
    product: Product
    payed: boolean
}



const PaymentCard = ({ payed, part, product, appointment, cart, payment }: PaymentCardProps) => {
    const charge = payment.preview ? payment.preview.amount : payment.paypal ? payment.paypal.charge.amount : 0
    const topay = cart.total - charge
    return (
        <div className="component-paymentcard">
            <Row className="invoice-top">
                {appointment &&
                    <>
                        <Col xs={12} sm={6} md={5} lg={4} className="d-flex bar">
                            <div className="bar-wrapper">
                                <Barcode value={appointment.barcode || appointment.folio} options={{ width: '100%', height: 'auto', format: 'EAN13' }} />
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={6} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column">
                            <h5>Código</h5>
                            <h2>{appointment.barcode || appointment.folio}</h2>
                            <h5>Servicio</h5>
                            <p><b>{part.name}</b></p>
                            <i>{product.model.name}</i>
                        </Col>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </>
                }
            </Row>
            {cart && 
            <Row className="invoice">
                <Col xs={12} className="mt-2 d-flex flex-column">
                    <h4>Desglose</h4>
                    <div className="service">
                        <div className="service-row">
                            <div className="concept">
                                <p><b>{part.name}</b> {product.variation ? product.variation.name : ''}</p>
                                <i>{product.model.name}</i>
                            </div>
                            <div className="amount">
                                <p><Currency value={cart.subtotal} /></p>
                            </div>
                        </div>
                        <div className="tax service-row">
                            <div className="concept">
                                <p>IVA</p>
                            </div>
                            <div className="amount">
                                <p><Currency value={cart.tax} /></p>
                            </div>
                        </div>
                        <div className="tax service-row">
                            <div className="concept">
                                <h5>Total</h5>
                            </div>
                            <div className="amount">
                                <h5><Currency value={cart.total} /></h5>
                            </div>
                        </div>
                        {charge && <div className={`total service-row ${payed ? 'payed' : ''}`}>
                            <div className="concept">
                                <h4>{payed ? 'Pagado' : 'Por pagar'}</h4>
                            </div>
                            <div className="amount">
                                <h3><Currency value={charge} /></h3>
                            </div>
                        </div>}
                        {payed && !!topay && <div className={`total service-row`}>
                            <div className="concept">
                                <h4>Por pagar</h4>
                            </div>
                            <div className="amount">
                                <h3><Currency value={topay} /></h3>
                            </div>
                        </div>}
                        
                    </div>
                </Col>
            </Row>
            }
        </div>
    )
}

export default PaymentCard
