import './style.scss'
import React, { useMemo } from 'react'
import { QuotationSelection } from '#interfaces/ReduxState'
import { Row, Col } from 'reactstrap'
import Img from '#components/Image'
import { fullname, addressPlanify } from '#interfaces/Quotation'
import moment from 'moment'
import Currency from '#components/Currency'


interface ServiceSummaryProps {
    selection: QuotationSelection
}

const ServiceSummary = (props: ServiceSummaryProps) => {

    const formattedDate = useMemo(() => moment(props.selection.date).format('LL'), [props.selection])
    const formattedTime = useMemo(() => {
        return `${moment(props.selection.appointmentslot.start, 'HH:mm:ss').format('h:mm a')} - ${moment(props.selection.appointmentslot.end, 'HH:mm:ss').format('h:mm a')}`
    }, [props.selection])
    const formattedAddress = useMemo(() => addressPlanify(props.selection.address), [props.selection])
    
    const part = useMemo(() => {
        return `${props.selection.part ? props.selection.part.name : props.selection.product.name}${props.selection.product.variation ? ` ${props.selection.product.variation.name}` : ''}`
    }, [props.selection])
    
    const service = useMemo(() => {
        if (props.selection.model) {
            return props.selection.model.name
        } else if (props.selection.line) {
            return `${props.selection.brand.name} ${props.selection.line.name}`
        } else {
            return `${props.selection.brand.name}`
        }
    }, [props.selection])

    const canpay = useMemo(() => props.selection.cart && !!props.selection.cart.total, [props.selection])

    


    return (
        <div className="component-servicesummary">
            <Row>
                <Col xs={12} lg={{ size: 10, offset: 1 }}>
                    <Row className="mb-5">
                        <Col xs={12} md={6} className="d-none d-md-flex justify-content-end pr-4">
                            <Img src="iconos/phone_lg.png" />
                        </Col>
                        <Col xs={12} md={6} className="d-flex flex-column justify-content-between">
                            <div className="personal-info">
                                <h4>Datos generales</h4>
                                <p>{fullname(props.selection.client)}</p>
                                <p>{props.selection.client.phone}</p>
                                <p>{props.selection.client.email}</p>
                                <p>{formattedDate}</p>
                                <p>{formattedTime}</p>
                                <p>{formattedAddress}</p>
                            </div>
                            <div className="office-info">
				<h4>Oficina:</h4>
                                <p>{props.selection.office.name}</p>
                            </div>
                            <div className="service-info">
                                <h4>{part}</h4>
                                <p>{service}</p>
                            </div>
                        </Col>
                    </Row>
                    {canpay ?
                        <>
                        <Row className="invoice mb-5">
                            <Col xs={12} className="mt-2 d-flex flex-column">
                                <div className="service">
                                    <div className="service-row">
                                        <div className="concept">
                                            <h4>{part}</h4>
                                        </div>
                                        <div className="amount">
                                            <h4><Currency value={props.selection.cart.subtotal} /></h4>
                                        </div>
                                    </div>
                                    <div className="tax service-row">
                                        <div className="concept">
                                            <h4>IVA</h4>
                                        </div>
                                        <div className="amount">
                                            <h4><Currency value={props.selection.cart.tax} /></h4>
                                        </div>
                                    </div>
                                    <div className="total service-row">
                                        <div className="concept">
                                            <h4>Total</h4>
                                        </div>
                                        <div className="amount">
                                            <h4><Currency value={props.selection.cart.total} /></h4>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        </>
                        :
                        <Row>
                            <Col className="mt-3 mb-3 text-center" xs={12}>
                                <h4>El costo del servicio se determinará una vez que valoremos tu equipo.</h4>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default ServiceSummary
