import './style.scss'
import React, { useMemo, useCallback } from 'react'
import _ from 'lodash'

interface ColumnRadioData {
    data: any, 
    label: string
}

interface ColumnRadioProps {
    options: ColumnRadioData[]
    onClick: (data: any) => void
    size: number
    selected: string
}

interface ColumnRadioElementProps {
    label: string
    selected: boolean
    onClick: () => void
}

const ColumnRadioElement = (props: ColumnRadioElementProps) => (
    <div onClick={props.onClick} className={`component-columnradioelement ${props.selected ? 'selected' : ''}`}>
        <div className={`radio`}></div>
        <label>{props.label}</label>
    </div>
)

const ColumnRadio = (props: ColumnRadioProps) => {
    const elementsize = useMemo(() => Math.round(props.options.length / props.size), [props])
    const columns = useMemo(() => _.chunk(props.options, elementsize), [props, elementsize])
    const radioClick = useCallback((data: any) => () => {
        props.onClick(data)
    }, [props])
    

    return (
        <div className="component-columnradio">
            {columns && columns.map(col => (
                <div className="column">
                    {col.map(c => (
                        <ColumnRadioElement 
                            label={c.label} 
                            selected={c.label === props.selected} 
                            onClick={radioClick(c.data)} 
                        />
                    ))}
                </div>
            ))}
        </div>
    )
}

export default ColumnRadio
