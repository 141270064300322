import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GTagFunction } from './global';
import { GAContext } from './GoogleAnalyticsProvider';
import { Location } from 'history';

declare const gtag:GTagFunction;


class PageViewListener extends React.Component<RouteComponentProps> {
    static contextType = GAContext
    context!: React.ContextType<typeof GAContext>

    eventPropagator: any
    unlisten: Function

    componentDidMount() {
        const { history } = this.props
        this.unlisten = history.listen((location) => {
            this.tag(location)
        });
        this.tag(history.location)
    }

    componentWillUnmount() {
        this.unlisten()
    }

    tag(location: Location) {
        if (this.eventPropagator) {
            clearTimeout(this.eventPropagator)
        }
        this.eventPropagator = setTimeout(() => {
            this.context.trackingId.forEach(track => {
                gtag('config', track, {
                    'page_path': location.pathname
                });
            })
        }, 1000)
    }

    render() {
        return (
            <>{this.props.children && this.props.children}</>
        )
    }
}

export default withRouter(PageViewListener)
