import './style.scss'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { RepairState } from '#interfaces/ReduxState'
import GoogleMapsAPI from '#helpers/maps/GoogleMapsAPI'
import { COMPANY } from '#constants'
import Barcode from 'react-barcode'
import { Sale, Cart, addressPlanify } from '#interfaces/Quotation'
import Currency from '#components/Currency'


interface MyRepairSummaryProps {
    repair: RepairState
}

const MyRepairSummary = ({ repair }: MyRepairSummaryProps) => {
    const technician = repair.technician && repair.technician.name ? repair.technician.name.trim() : 'No asignado';
    const sale: Sale = repair.sale;
    const products: Array<Cart> = sale && sale.products ? sale.products : null;

    const mapsrender = useMemo(() => {
        if (repair.homeservice) {
            return `${repair.homeservice.address1}, C.P. ${repair.homeservice.zipcode}`
        }
        return `${repair.office.place ? `place_id:${repair.office.place}` : `${COMPANY.NAME} ${repair.office.name}`}`
    }, [repair])


    return (
        <div className="component-myrepairsummary">
            <Row>
                <Col xs={12} sm={12} md={12} lg={5} className="d-flex bar">
                    {repair.barcode && <div className="bar-wrapper">
                        <Barcode value={repair.barcode} options={{ width: '100%', height: 'auto', format: 'EAN13' }} />
                    </div>}
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} className="mt-3 mt-md-0 md-lg-0 d-flex align-items-center text-center text-lg-left align-items-lg-start flex-column">
                    <h5>Código</h5>
                    <h2>{repair.barcode && _.chunk(repair.barcode.split(''),4).map(chunk => chunk.join('')).join('-')}</h2>
                    <hr />
                    <h5>Diagnostico</h5>
                    <h4 className="text-highlight"><b>{repair.diagnostic}</b></h4>
                    <hr />
                    <h5>Estado</h5>
                    <h4>{repair.status.name}</h4>
                    <i>{repair.status.label}</i>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
                <Col xs={12}>
                    <Row>
                        <Col xs={12} md={12} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column align-items-center text-center  personal-data">
                            <h5>Dispositivo</h5>
                            <p><b>{repair.device.name || 'N/A'}</b></p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
                {
                    products &&
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} md={12} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column align-items-center text-center  personal-data">
                                <h5>Productos</h5>
                            </Col>
                            <Col xs={12} md={12} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column align-items-center text-center  personal-data">
                                {products.map((product: Cart) => (
                                    <Row key={product.product}>
                                        <Col xs={12} className="d-flex justify-content-center">
                                            <img src={product.photo} width="100px" alt={product.product} />
                                        </Col>
                                        <Col xs={12} className="d-flex justify-content-center text-center">
                                            <b>{product.product}</b>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    </Col>
                }
                <Col xs={12}>
                    <hr />
                </Col>
                {
                    sale &&
                    <Col xs={12}>
                        <Row>
                            <Col xs={4} md={4} className="text-center">
                                <h5>Total</h5>
                                <p><Currency value={sale.total} /> </p>
                            </Col>
                            <Col xs={4} md={4} className="text-center">
                                <h5>Pagado</h5>
                                <p className="text-success"><Currency value={sale.paid} /></p>
                            </Col>
                            <Col xs={4} md={4} className="text-center">
                                <h5>Por pagar</h5>
                                <p className="text-danger"><Currency value={sale.total - sale.paid} /></p>
                            </Col>
                        </Row>
                    </Col>
                }
                <Col xs={12}>
                    <hr />
                </Col>
                <Col xs={12}>
                    <Row>
                        <Col xs={12} md={4} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column align-items-center text-center  personal-data">
                            <h5>Técnico</h5>
                            <p><b>{technician || 'No asignado'}</b></p>
                        </Col>
                        <Col xs={12} md={4} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column align-items-center text-center appointment">
                            <h5>Proceso</h5>
                            <p><b>{repair.process.name}</b></p>
                        </Col>
                        <Col xs={12} md={4} className="mt-3 mt-md-0 md-lg-0 d-flex flex-column align-items-center text-center appointment">
                            <h5>Sucursal</h5>
                            <p><b>{repair.office.name}</b></p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
                <Col xs={12}>
                    <Row>
                        {repair.homeservice && 
                            <Col xs={12} className="mt-3 mt-md-3 md-lg-0 d-flex flex-column appointment text-center">
                                <h5>Dirección</h5>
                                <p><b>{addressPlanify(repair.homeservice)}</b></p>
                                <i>{repair.homeservice.extrainformation || ''}</i>
                            </Col>
                        }
                        <Col xs={12} className="mt-3 mt-md-3 md-lg-0 d-flex flex-column appointment">
                            {GoogleMapsAPI.render(mapsrender, process.env.REACT_APP_GOOGLE_API_KEY)}
                        </Col>
                        
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default MyRepairSummary
