import './style.scss'
import _ from 'lodash'
import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { connect } from 'react-redux'
import { Client, QuotationPageProps, Product } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { createClient, createCart } from '#actions/quotation'
import IOForm, { IOInput } from 'react-io-forms'
import ReduxState from '#interfaces/ReduxState'
import { QuotationPageContext } from '#screens/Quotation'
import { COMPANY, GAEVENT_CATS } from '#constants'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'

interface QuotationClientInjectedProps {
    client?: Client
    product?: Product
}


class QuotationClient extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuotationClientInjectedProps & ThunkDispatchProp> {

    state = {
        loading: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        
    }

    onSubmitInfo = async (info: Client) => {
        const { dispatch, onFinish, product } = this.props
        this.setState({ loading: true })
        await dispatch(createClient(info))
        await dispatch(createCart(product ? [{ idProduct: product.idProduct, quantity: 1 }] : []))
        
        this.props.analytics.gtag('event', 'create_client_cart', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: product.name })
        onFinish()
    }

    render() {
        const client: Client = _.get(this.props, 'client') || {}

        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: true, loading: !!this.state.loading }) &&
                    <div className="container-quotationclient">
                        <Container>
                            <IOForm id="quotation-sequence-form" onSubmit={this.onSubmitInfo}>
                                <Row className="mt-4">
                                    <Col xs={12} sm={6} className="mt-2">
                                        <IOInput value={client.firstname} label="Nombre *" name="firstname" required maxLength={32} />
                                    </Col>
                                    <Col xs={12} sm={6} className="mt-2">
                                        <IOInput value={client.lastname} label="Apellido *" name="lastname" required maxLength={32} />
                                    </Col>
                                    <Col xs={12} sm={6} className="mt-2">
                                        <IOInput value={client.email} label="Correo electrónico *" validate="email" required name="email" maxLength={128} />
                                    </Col>
                                    <Col xs={12} sm={6} className="mt-2">
                                        <IOInput value={client.cellphone} label="Celular *" name="cellphone" required maxLength={12} />
                                    </Col>
                                    <Col xs={12} sm={6} className="mt-2">
                                        <IOInput value={client.phone} label="Teléfono fijo" name="phone" maxLength={12} />
                                    </Col>
                                    <Col xs={12} className="mt-4">
                                        <IOInput value={true} label="Recibir notificaciones de tu servicio por correo" filterOut={v => v ? 1 : 0} name="servicemail" type="checkbox" />
                                    </Col>
                                    <Col xs={12}>
                                        <IOInput value={false} label="Recibir promociones por correo" filterOut={v => v ? 1 : 0} name="promotionsmail" type="checkbox" />
                                    </Col>
                                    <Col xs={12}>
                                        <IOInput value={false} required label={<a className="dark" target="__blank" href={COMPANY.TERMS_LINK} >Acepto los términos y condiciones de uso</a>} filterOut={v => v ? 1 : 0} name="_terms" type="checkbox" />
                                    </Col>
                                    <Col xs={12}>
                                        <IOInput value={false} required label={<a className="dark" target="__blank" href={COMPANY.PRIVACY_LINK} >Acepto el aviso de privacidad</a>} filterOut={v => v ? 1 : 0} name="_terms" type="checkbox" />
                                    </Col>
                                </Row>
                            </IOForm>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationClientInjectedProps>((state: ReduxState) => ({
    client: state.quotation.selection.client,
    product: state.quotation.selection.product
}),dispatchMap)(withGoogleAnalytics(QuotationClient))