import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import QueryString from 'query-string'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import ReduxState from '#interfaces/ReduxState'
import { Row, Col, Fade, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PaymentSelect from '#components/PaymentSelect'
import { PaymentMethodEnum, PaypalPayment } from '#interfaces/Payment'
import PaymentConekta from '#components/PaymentConekta'
import TitleBar from '#components/TitleBar'
import { ConektaTokenResponse } from '#helpers/conekta/interfaces'
import MaterialIcon from 'material-icons-react'
import { payConekta, setSelectedPaymentMethod, createPaypalOrder, confirmPaypal } from '#actions/payment'
import { Cart } from '#interfaces/Quotation'
import PaymentCash from '#components/PaymentCash'
import PaymentPhysicalTerminal from '#components/PaymentPhysicalTerminal'
import { setPaymentType } from '#actions/quotation'
import PaymentPaypal from '#components/PaymentPaypal'
import { useEffectMount } from '#helpers/hooks'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PAYPAL_TOKEN, PAYPAL_FAIL_FLAG, GAEVENT_CATS } from '#constants'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'

declare global {
    interface Window { fbq: Function; }
}

interface PaymentBoxInjected {
    cart: Cart
    payment: PaymentMethodEnum
    paypal: PaypalPayment
}

interface PaymentBoxProps {
    onPayed: () => void
    localonly?: boolean
}

const PaymentBox = (props: WithGoogleAnalyticsProps & PaymentBoxProps & ThunkDispatchProp & PaymentBoxInjected & RouteComponentProps) => {
    const [ busy, setBusy ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ redirect, setRedirect ] = useState(false)

    const reset = useCallback(() => {
        props.dispatch(setSelectedPaymentMethod(null))
    }, [props])

    const toggleError = useCallback(() => {
        setError(!error)
    }, [error])

    const onSelectPayment = useCallback((payment: PaymentMethodEnum) => {
        props.dispatch(setSelectedPaymentMethod(payment))
    }, [props])


    // CONEKTA PAY
    const conekta = useCallback(async (value: ConektaTokenResponse) => {
        setBusy(true)
        try {
            await props.dispatch(payConekta(props.cart, value.token.id))
            props.analytics.gtag('event', 'add_payment_info', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: PaymentMethodEnum.CONEKTA })
            props.analytics.gtag('event', 'purchase', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: props.cart.product })
            window.fbq('track', 'Purchase', { currency: "MXN", value: props.cart.total});
            props.onPayed()
            
        } catch(e) {
            setBusy(false)
            setError(true)
        }
    }, [props])

    // PAYPAL PAY  -----
    const paypal = useCallback(async () => {
        setBusy(true)
        try {
            window.fbq('track', 'Purchase', { currency: "MXN", value: props.cart.total});
            setRedirect(true)
            await props.dispatch(createPaypalOrder(props.cart))
            setBusy(false)
        } catch(e) {
            setBusy(false)
            setError(true)
        }
    }, [props])
    const paypalFinish = useCallback(async (token: string) => {
        setBusy(true)
        try {
            await props.dispatch(confirmPaypal(token))
            props.analytics.gtag('event', 'add_payment_info', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: PaymentMethodEnum.PAYPAL })
            props.analytics.gtag('event', 'purchase', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: props.cart.product })
            
            props.onPayed()
        } catch(e) {
            setBusy(false)
            setError(true)
        }
    }, [props])
    useEffect(() => {
        if (redirect && props.paypal && props.paypal.approve_url && !props.paypal.charge) {
            window.location.href = props.paypal.approve_url.href
        }
    }, [props.paypal, redirect])
    useEffectMount(() => {
        const { history } = props
        const query = QueryString.parse(history.location.search)
        const token = query[PAYPAL_TOKEN] as string
        const fail  = query[PAYPAL_FAIL_FLAG] as string

        if (fail) {
            setError(true)
        } else if (token) {
            paypalFinish(token)
        }
    })
    // END PAYPAL -----

    // CASH & PHYSICAL TERMINAL
    const markMethod = useCallback(async () => {
        setBusy(true)
        try {
            await props.dispatch(setPaymentType(props.cart, props.payment))
            props.analytics.gtag('event', 'add_payment_info', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: props.payment })
            props.analytics.gtag('event', 'purchase', { event_category: GAEVENT_CATS.ECOMMERCE, event_label: props.cart.product })
            props.onPayed()
        } catch(e) {
            setBusy(false)
            setError(true)
        }
    }, [props])
    

    return (
        <div className="container-paymentbox">
            <Row>
                <Col xs={12}>
                    <TitleBar simple title="Métodos de pago" />
                </Col>
            </Row>
            {props.payment && 
                <Row className="mb-3">
                    <Col xs={12}>
                        <Button color="black" className="d-flex align-items-center" outline onClick={reset}>
                            <MaterialIcon icon="keyboard_arrow_left" />
                            Seleccionar otro
                        </Button>
                    </Col>
                </Row>
            }
            <Row>
                <Col xs={12}>
                    {!props.payment && 
                        <Fade>
                            <PaymentSelect localonly={props.localonly} onSelect={onSelectPayment} />
                        </Fade>
                    }
                    {props.payment === PaymentMethodEnum.CONEKTA &&
                        <Fade>
                            <PaymentConekta onSubmit={conekta} conektaKey={process.env.REACT_APP_CONEKTAKEY} />
                        </Fade>
                    }
                    {props.payment === PaymentMethodEnum.CASH &&
                        <Fade>
                            <PaymentCash onSubmit={markMethod} busy={busy} />
                        </Fade>
                    }
                    {props.payment === PaymentMethodEnum.PHYSICAL_TERMINAL &&
                        <Fade>
                            <PaymentPhysicalTerminal onSubmit={markMethod} busy={busy} />
                        </Fade>
                    }
                    {props.payment === PaymentMethodEnum.PAYPAL &&
                        <Fade>
                            <PaymentPaypal onSubmit={paypal} busy={busy} />
                        </Fade>
                    }
                </Col>
            </Row>
            <Modal isOpen={error}>
                <ModalHeader>
                    Error
                </ModalHeader>
                <ModalBody>
                    Ha ocurrido un error al intentar marcar tu selección o realizar tu pago. Selecciona otra forma de pago y vuelve a intentar.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleError}>Entendido</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default connect<PaymentBoxInjected, {}, PaymentBoxProps>((state: ReduxState) => ({
    cart: state.quotation.selection.cart,
    payment: state.payment.payment_method,
    paypal: state.payment.paypal
}), dispatchMap)(withRouter(withGoogleAnalytics(PaymentBox)))
