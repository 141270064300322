import update from 'immutability-helper';
import { QuotationState } from '#interfaces/ReduxState';

// ACTION TYPES DECLARED AT USER ACTION CONSTANTS
import { ReduxAction } from '#interfaces/ReduxActions';
import { TYPE_SET_APPOINTMENT, TYPE_SET_APPOINTMENTSLOTS, TYPE_SET_BRANDS, TYPE_SET_CART, TYPE_SET_CITIES, TYPE_SET_SELECTED_CLIENT, TYPE_SET_LINES, TYPE_SET_MODELS, TYPE_SET_OFFICES, TYPE_SET_PARTS, TYPE_SET_PRODUCTS, TYPE_RESET_QUOTATION, TYPE_SET_SELECTED_MODEL, TYPE_SET_SELECTED_OFFICE, TYPE_SET_SELECTED_ASLOT, TYPE_SET_SELECTED_PART, TYPE_SET_SELECTED_PRODUCT, TYPE_SET_SUMMARYVIEWED, TYPE_SET_SELECTED_APPOINTMENTDATE, TYPE_SET_SELECTED_PAYED, TYPE_SET_PAGE, TYPE_SET_SELECTED_LINE, TYPE_SET_SELECTED_BRAND, TYPE_SET_SELECTED_TYPE, TYPE_SET_SELECTED_ADDRESS, TYPE_SET_SELECTED_CITY, TYPE_SET_SELECTED_APPOINTMENTONLY } from '#actions/quotation';


// REDUCER FUNCTION
export default function quotation ( state: QuotationState = { }, action: ReduxAction<QuotationState> ) {
	switch ( action.type ) {
		case TYPE_SET_PAGE:
			return update(state, { page: { $set: action.value.page } })
		case TYPE_SET_SELECTED_BRAND:
				return update(state, { selection: { $merge: {...action.value.selection, line: null, model: null, part: null, product: null } } })
		case TYPE_SET_SELECTED_LINE:
				return update(state, { selection: { $merge: {...action.value.selection, model: null, part: null, product: null } } })
		case TYPE_SET_SELECTED_MODEL:
				return update(state, { selection: { $merge: {...action.value.selection, part: null, product: null } } })
		case TYPE_SET_SELECTED_PART:
				return update(state, { selection: { $merge: {...action.value.selection, product: null } } })	
		
		case TYPE_SET_APPOINTMENT:
		case TYPE_SET_SELECTED_PRODUCT:
		case TYPE_SET_SELECTED_OFFICE:
		case TYPE_SET_SELECTED_ASLOT:	
		case TYPE_SET_SELECTED_CLIENT:
		case TYPE_SET_SUMMARYVIEWED:
		case TYPE_SET_SELECTED_APPOINTMENTDATE:
		case TYPE_SET_SELECTED_PAYED:
		case TYPE_SET_CART:
		case TYPE_SET_SELECTED_TYPE:
		case TYPE_SET_SELECTED_ADDRESS:
		case TYPE_SET_SELECTED_CITY: 
		case TYPE_SET_SELECTED_APPOINTMENTONLY:
			return update(state, { selection: { $merge: action.value.selection } })
		case TYPE_SET_APPOINTMENTSLOTS:
			return update(state, { appointmentslots: { $set: action.value.appointmentslots } })
		case TYPE_SET_BRANDS:
			return update(state, { brands: { $set: action.value.brands } })
		case TYPE_SET_CITIES:
			return update(state, { cities: { $set: action.value.cities } })
		case TYPE_SET_LINES:
			return update(state, { lines: { $set: action.value.lines } })
		case TYPE_SET_MODELS:
			return update(state, { models: { $set: action.value.models } })
		case TYPE_SET_OFFICES:
			return update(state, { offices: { $set: action.value.offices } })
		case TYPE_SET_PARTS:
			return update(state, { parts: { $set: action.value.parts } })
		case TYPE_SET_PRODUCTS:
			return update(state, { products: { $set: action.value.products } })
		case TYPE_RESET_QUOTATION:
			return update(state, { $set: { selection: {}, page: 0 }})
		default:
			return state;
	}
}