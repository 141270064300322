import { RepairState } from "#interfaces/ReduxState";
import { ActionCreator } from "redux";
import { ReduxAction } from "#interfaces/ReduxActions";
import APIClient from "#helpers/APIClient";
import { APIResponse } from "#interfaces/Quotation";

// const _repair = {"success":"OK","message":"MiReparacion::search","data":{"idService":1,"folio":"434QVLK9C6L3","created":"2016-03-28 13:07:02","diagnostic":"PANTALLA COMPLETA","status":"Completed","process":"Listo","technician":"Tecnico Prueba","office":"Sucursal Matriz"}}

export const TYPE_SET_REPAIR = '@Repair/SET_REPAIR';

export const setRepair:ActionCreator<ReduxAction<RepairState>> = (repair: RepairState) => {
    return {
        type: TYPE_SET_REPAIR,
        value: repair
    }
}

export const getRepair = (folio: string) => async (dispatch): Promise<void> => {
    // GET PARTS
    const response = await APIClient.get().post(`/mireparacion/operation:search/folio:${folio}`, {})
    const body = await response.json() as APIResponse<RepairState>
    dispatch(setRepair(body.data))
} 


