import { updateIntl } from 'react-intl-redux';
import { getMessages } from '../i18n';

/**
* Action Function to set the change the Application's language
* @type function
* @name Action.changeLanguage
* @param {String} code ISO locale code.
* @public
*/
export const changeLanguage = (code: string) => {
	return updateIntl({
		locale: code,
		messages: getMessages(code)
	});
};