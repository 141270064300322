import './style.scss'
import React from 'react'
import IOForm from 'react-io-forms'
import { IOInput } from 'react-io-forms'
import { Row, Col, Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { connect } from 'react-redux'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { getRepair } from '#actions/repair'
import ReduxState from '#interfaces/ReduxState'
import { imageUrl } from '#helpers/utils'

export interface MiReparacionWidgetProps {
    onRepair?: (criteria: string) => void
}

class MiReparacionWidget extends React.PureComponent<MiReparacionWidgetProps & ThunkDispatchProp> {

    state = {
        busy: false,
        error: false,
        folio: null
    }

    onSubmit = async ({ folio }) => {
        const { dispatch, onRepair } = this.props
        this.setState({ busy: true })
        try {
            await dispatch(getRepair(folio))
            this.setState({ busy: false }, () => {
                if (onRepair) { onRepair(folio) }
            })
        } catch(e) {
            this.setState({ busy: false, error: true, folio })
        }
    }

    toggle = () => {
        this.setState({ error: !this.state.error })
    }

    render() {
        return (
            <div className="container-mireparacionwidget">
                <IOForm onSubmit={this.onSubmit}>
                    <Row>
                        <Col xs={2} style={{ backgroundImage: `url(${imageUrl('iconos/phone.png')})` }} className="d-none d-lg-block decorator left-decorator" />
                        <Col xs={12} md={12} lg={8} className="main-container">
                            <Row>
                                <Col md={12} className="d-flex justify-content-center text-center mb-3">
                                    <h3>Conoce el estado de tu reparación</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={8} className="input-wrapper mt-4">
                                    <IOInput required placeholder="Ingresa tu código ó folio" type="text" name="folio" />
                                    {this.state.busy && <Spinner size="sm" color="highlight" />}
                                </Col>
                                <Col xs={12} md={12} lg={4} className="mt-4">
                                    <Button type="submit" block color="black">
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={2} style={{ backgroundImage: `url(${imageUrl('iconos/repair_amarillo.png')})` }} className="d-none d-lg-block decorator right-decorator" />
                    </Row>
                </IOForm>
                <Modal isOpen={this.state.error} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Servicio no encontrado</ModalHeader>
                    <ModalBody>
                        No podemos encontrar tu servicio con el códgo o folio <b>{this.state.folio}</b>. Revisa que esté bien escrito y vuelve a intentar.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>Ok</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect((state: ReduxState) => ({}), dispatchMap)(MiReparacionWidget)