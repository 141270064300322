import './style.scss'
import React from 'react'
import { Row, Col, Fade, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'
import ReduxState, { GeoPosition } from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { QuotationPageProps, QuotationAddress, QuotationTypeCatalog, QuotationCity, Office } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { getOffices, setQuotationAddress, setSelectedCity, setQuotationType, setOfficeByAddress, getOfficesByAddress, setSelectedOffice, setOffices, setPage } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha, sortByDistance, ArrayDistanceSorted } from '#helpers/utils'
import CardButton from '#components/CardButton'
import IOForm, { IOInput } from 'react-io-forms'
import { QuotationPageContext, PAGES } from '#screens/Quotation'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CardOfficeButton from '#components/CardOfficeButton'
import ActionBuffer from '#helpers/ActionBuffer'
import GoogleMapPinPoint from '#helpers/maps/GoogleMapPinPoint'
import GoogleMapsAPI, { GeocodeResult } from '#helpers/maps/GoogleMapsAPI'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import { GAEVENT_CATS } from '#constants'

interface QuotationCitiesInjectedProps {
    cities?: QuotationCity[]
    address?: QuotationAddress
    type?: QuotationTypeCatalog
    city?: QuotationCity
    offices?: Office[]
    position?: GeoPosition
    office?: Office
}

const buffer = new ActionBuffer(700)


class QuotationCities extends React.Component<WithGoogleAnalyticsProps & QuotationPageProps & QuotationCitiesInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null,
        address_not_available: false,
        busy: false,
        search_input: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const { address, dispatch } = this.props
        if (!address || !address.zipcode) {
            dispatch(setOffices([]))
        }
    }

    cityClick = (city: QuotationCity) => async () => {
        const { dispatch, onFinish } = this.props
        this.setState({ loading: city.idCity })
        await dispatch(setQuotationAddress(null))
        await dispatch(setSelectedCity(city))
        await dispatch(getOffices(city.idCity))
        onFinish()
    }

    clickOffice = (office: Office) => async () => {
        const { dispatch } = this.props
        this.setState({ loading: office.idOffice })
        await dispatch(setSelectedOffice(office))
        dispatch(setPage(PAGES.ASLOTS))
    }

    onAddress = async (address: QuotationAddress) => {
        const { dispatch, onFinish, city, type, address: stored } = this.props
        this.setState({ loading: true })
        
        if (address && address.zipcode) {
            
            await dispatch(setSelectedCity(null))
            if (type === QuotationTypeCatalog.HOME_SERVICE) {
                try {
                    await dispatch(setQuotationAddress({ ...stored, ...address }))
                    await dispatch(setOfficeByAddress(address))
                } catch(e) {
                    this.setState({ address_not_available: true })
                }
            } else {
                await dispatch(setQuotationAddress(address))
                await dispatch(getOfficesByAddress(address))
            }
        }
        if ((address && address.zipcode) || city) {
            onFinish()
        }
        
    }

    evalAddress = async (address: QuotationAddress) => {
        const { dispatch } = this.props
        this.setState({ busy: true })
        buffer.execute(async (ad) => {
            dispatch(setQuotationAddress(ad))
            if (ad.zipcode && ad.zipcode.length >= 5) {
                await dispatch(getOfficesByAddress(ad))
            } else {
                dispatch(setOffices([]))
            }
            this.setState({ busy: false })
        }, address)
        
    }


    localService = () => {
        const { dispatch } = this.props
        dispatch(setQuotationType(QuotationTypeCatalog.LOCAL_SERVICE))
        this.setState({ address_not_available: false })
    }

    toggleModal = () => {
        const { address_not_available } = this.state
        this.setState({ address_not_available: !address_not_available })
    }

    setSearchInputRef = (el) => {
        const { search_input } = this.state
        if (!search_input && el) {
            this.setState({ search_input: el })
        }
    }

    setSelectedAddress = (place: GeocodeResult) => {
        const { dispatch, address } = this.props

        const street = GoogleMapsAPI.extractAddressNameByTypes(place, ['route', 'street_address'])
        const number = GoogleMapsAPI.extractAddressNameByTypes(place, ['street_number'])

        if (street && number) {
            const address2 = GoogleMapsAPI.extractAddressNameByTypes(place, ['sublocality', 'sublocality_level_1'])
            const city = GoogleMapsAPI.extractAddressNameByTypes(place, ['locality'])
            const state = GoogleMapsAPI.extractAddressNameByTypes(place, ['administrative_area_level_1'])
            const zipcode = GoogleMapsAPI.extractAddressNameByTypes(place, ['postal_code'])
            const position = place.geometry.location.toJSON()

            dispatch(setQuotationAddress({
                ...address,
                address1: `${street} ${number}`,
                address2,
                city,
                state,
                zipcode,
                position
            }))
            this.props.analytics.gtag('event', 'set_client_address', { event_category: GAEVENT_CATS.ECOMMERCE })
        }
        
    }

    render() {
        const { cities, address, type, city, offices, position, office } = this.props
        const { loading, address_not_available, busy } = this.state
        const officessorted = position ? sortByDistance<Office>(offices, position, o => o.location) : sortAlpha<Office>(offices, 'name')
        const defaultPosition = address.position || (position ? { lat: position.latitude, lng: position.longitude } : undefined)
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: true, loading: !!loading }) &&
                    <div className="container-quotationcities">
                        <Container>
                            <Busy busy={!cities}>
                                {type === QuotationTypeCatalog.LOCAL_SERVICE ? <>
                                    <IOForm id="quotation-sequence-form" onSubmit={this.onAddress}>
                                        <Row>
                                            <Col xs={12} sm={6} md={4}>
                                                <IOInput onValue={(zipcode) => this.evalAddress({ zipcode })} prepend={busy ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faMapMarkerAlt} />} value={address.zipcode} label="Código postal" name="zipcode" validate="numeric" maxLength={5} />
                                            </Col>
                                        </Row>
                                    </IOForm>
                                    <Row>
                                        {officessorted && officessorted.map(((o: Office & ArrayDistanceSorted) => (
                                            <Col xs={12} sm={6} md={6} lg={4} key={o.idOffice} className="mt-3 office">
                                                <Fade>
                                                    <CardOfficeButton selected={office ? office.idOffice === o.idOffice : undefined} busy={loading === o.idOffice} office={o} onClick={this.clickOffice(o)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                    </Row>
                                    {(!officessorted || !officessorted.length) && !address.zipcode && 
                                        <>
                                            <Row>
                                                <Col className="mb-2" xs={12}>
                                                    <h4>ó selecciona una de las ciudades</h4>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {cities && sortAlpha(cities, 'name').map(((c: QuotationCity) => (
                                                    <Col xs={12} sm={6} md={4} lg={3} key={c.idCity} className="mt-1">
                                                        <Fade>
                                                            <CardButton selected={city ? city.idCity === c.idCity : undefined} busy={loading === c.idCity} name={c.name} onClick={this.cityClick(c)} />
                                                        </Fade>
                                                    </Col>
                                                )))}
                                            </Row>
                                        </>
                                    }
                                    {(!officessorted || !officessorted.length) && address.zipcode && address.zipcode.length >= 5 && !busy && 
                                        <>
                                            <Row>
                                                <Col className="mb-2" xs={12}>
                                                    <h6>No se encontraron resultados</h6>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <Row>
                                        <Col xs={12} sm={6} md={8}>
                                            <IOInput innerRef={this.setSearchInputRef} label="Dirección" name="address_search" maxLength={256} />
                                        </Col>
                                        <Col xs={12}>
                                            {this.state.search_input && 
                                                <GoogleMapPinPoint
                                                    defaultPosition={defaultPosition}
                                                    apikey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                    onLocate={this.setSelectedAddress}
                                                    input={this.state.search_input}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <IOForm<QuotationAddress> id="quotation-sequence-form" onSubmit={this.onAddress}>
                                        <Row>
                                            <Col className="d-none">
                                                <IOInput required type="hidden" value={address.address1} name="address1" /> 
                                                <IOInput type="hidden" value={address.address2} name="address2" /> 
                                                <IOInput required type="hidden" value={address.city} name="city" />
                                                <IOInput required type="hidden" value={address.state} name="state" />
                                                <IOInput required type="hidden" value={address.zipcode} name="zipcode" />
                                            </Col>
                                            <Col className="mt-4" xs={12}>
                                                <IOInput type="textarea" value={address.extrainformation} label="Depto, interior u otras señas particulares del inmueble" name="extrainformation" maxLength={300} />
                                            </Col>
                                        </Row>
                                    </IOForm>
                                </>
                                }
                            </Busy>
                        </Container>
                        <Modal isOpen={address_not_available} toggle={this.toggleModal}>
                            <ModalHeader toggle={this.toggleModal}>Dirección fuera de area</ModalHeader>
                            <ModalBody>
                                Por el momento no tenemos servicio a domicilio para la dirección postal que nos proporcionaste. Puedes intentar con
                                &nsbp;otra dirección postal o cambiar a <b>Servicio en sucursal</b>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="muted" onClick={this.toggleModal}>Cambiar dirección</Button>{' '}
                                <Button color="primary" onClick={this.localService}>Servicio en sucursal</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                }   
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationCitiesInjectedProps>((state: ReduxState) => ({ 
    cities: state.quotation.cities,
    address: state.quotation.selection.address || {},
    city: state.quotation.selection.city,
    type: state.quotation.selection.type,
    offices: state.quotation.offices,
    office: state.quotation.selection.office,
    position: state.quotation.selection.position || state.user.position
}), dispatchMap)(withGoogleAnalytics(QuotationCities))