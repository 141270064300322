import React, { useMemo, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import GoogleMapsAPI, { GeocodeResult, LatLngLiteral } from './GoogleMapsAPI'

interface GoogleMapPinPointProps {
    onLocate: (result: GeocodeResult) => void
    defaultPosition?: LatLngLiteral
    apikey: string
    inputId?: string
    input?: HTMLInputElement
}

const useMountEffect = (fn) => useEffect(fn, [])

const GoogleMapPinPoint = (props: GoogleMapPinPointProps) => {
    
    const [ google, setGoogle ] = useState(GoogleMapsAPI.google)
    const [ center, setCenter ] = useState(null)
    const [ defaultPosition ] = useState(props.defaultPosition)
    const [ loaded, setLoaded ] = useState(false)
    const [ mapdiv, setMapDiv ] = useState(null)

    const id = useMemo(() => `map-pinpoint-${Math.round(Math.random()*100000)}`, [])
    
    const map = useMemo(() => {
        if (mapdiv && google) {
            return new google.maps.Map(mapdiv, { 
                zoom: 16, center: defaultPosition,
                disableDefaultUI: true
            });
        } 
    }, [google, defaultPosition, mapdiv])

    const marker = useMemo(() => {
        if (map) {
            return new google.maps.Marker({
                position: defaultPosition,
                map: map,
                title:"Ubicación",
                
            })
        }
    }, [map, defaultPosition, google])

    const input = useMemo(() => {
        if (props.inputId) {
            return document.getElementById(props.inputId) as HTMLInputElement
        } else if (props.input) {
            return props.input
        }
    }, [props.inputId, props.input])


    const autocomplete = useMemo(() => {
        if (google) {
            return new google.maps.places.Autocomplete(input, {
                types: ['address'],
                componentRestrictions: {country: 'mx'}
            });
        }
    }, [google, input])

    const captureRef = useCallback((el) => {
        if (!mapdiv && el) {
            setMapDiv(el)
        }
    }, [mapdiv, setMapDiv])

    const loadGoogle = useCallback(async () => {
        setGoogle(await GoogleMapsAPI.get(props.apikey))
    }, [props.apikey])

    const setPlace = useCallback((result: GeocodeResult) => {
        if (result) {
            props.onLocate(result)
            setLoaded(true)
            input.value = result.formatted_address
        }
    }, [input.value, props, setLoaded])

    const initialLoad = useCallback(async () => {
        const result = await GoogleMapsAPI.geocode({ location: defaultPosition })
        setPlace(result[0])
    }, [setPlace, defaultPosition])


    useLayoutEffect(() => {
        if (map && center) {
            map.setCenter(center)
        }
        if (marker && center) {
            marker.setPosition(center)
        }
    }, [center, map, marker])


    useEffect(() => {
        if(autocomplete) {
            const evt1 = google.maps.event.addListener(autocomplete, 'place_changed', function () {
                const place = this.getPlace() as GeocodeResult
                if (place) {
                    const location = place.geometry.location
                    setCenter({ lat: location.lat(), lng: location.lng() }); // Set map center to marker position
                    setPlace(place)
                }
            });
            return () => {
                google.maps.event.removeListener(evt1)
            }
        }
    }, [autocomplete, google, input, props, setPlace])


    useEffect(() => {
        if (map && google && marker) {
            const evt1 = google.maps.event.addListener(map, 'drag', function () {
                marker.setPosition(this.getCenter())
            });
    
            const evt2 = google.maps.event.addListener(map, 'dragend', async function () {
                const location = this.getCenter()
                const result = await GoogleMapsAPI.geocode({ location })
                setCenter({ lat: location.lat(), lng: location.lng() });
                setPlace(result[0])
            });

            return () => {
                google.maps.event.removeListener(evt1)
                google.maps.event.removeListener(evt2)
            } 
        }
        
    }, [google, marker, props, map, input.value, setPlace])

    useEffect(() => {
        if (google && !loaded) {
            initialLoad()
        }
    }, [google, loaded, initialLoad])
        
    useMountEffect(() => { loadGoogle() })

    return (
        <div style={{ minHeight: '210px' }} key={id} ref={captureRef} />
    )
}

export default GoogleMapPinPoint
