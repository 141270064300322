import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import { addLocaleData } from 'react-intl';


export const AvailableLanguages = {
	EN: ['en','en-us'],
	ES: ['es','es-mx']
};

/*eslint-disable */
const messagesEn = require('../i18n/reactIntlMessages-en-US.json');
const messagesES = require('../i18n/reactIntlMessages-es-MX.json');
/*eslint-enable */

export const getMessages = (code) => {
	let messages = null;
	if (!code) {
		code = '';
	}
	switch (code.toLowerCase()) {
		case 'en' :
		case 'en-us' :
			messages = messagesEn;
			break;
		case 'es' :
		case 'es-mx' :
		case 'es-es' :
		case 'es-us' :
		default :
			messages = messagesES;
			break;
	}
	return messages;
};

export const initLocaleData = () => {
	addLocaleData([...en, ...es]);
};
