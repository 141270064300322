import './style.scss'
import React from 'react'
import { Row, Col } from 'reactstrap'
import Infograph from '#components/Infograph'
import Img from '#components/Image'
import { connect } from 'react-redux'
import ReduxState, { GeneralStats } from '#interfaces/ReduxState'
import { formatNumber } from '#helpers/utils'
import { useInterval } from '#helpers/hooks'
import { ThunkDispatchProp } from '#interfaces/ReduxActions'
import { getStats } from '#actions/general'

interface GeneralDataWidgetInjected {
    stats: GeneralStats
}

const GeneralDataWidget = (props: GeneralDataWidgetInjected & ThunkDispatchProp) => {

    useInterval(() => { props.dispatch(getStats()) }, 30000, true)

    return (
        <div className="container-generaldatawidget">
            <Row>
                <Col xs={12} md={6} lg={3}>
                    <Infograph
                        icon={<Img size={50} src="iconos/home_trophy.png" />}
                        title="+ 10 años"
                        subtitle="En el mercado"
                    /> 
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Infograph
                        icon={<Img size={50} src="iconos/home_like.png" />}
                        title={formatNumber(props.stats.clients)}
                        subtitle="Clientes satisfechos"
                    /> 
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Infograph
                        icon={<Img size={50} src="iconos/home_repair.png" />}
                        title={formatNumber(props.stats.services)}
                        subtitle="Servicios realizados"
                    /> 
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <Infograph
                        icon={<Img size={50} src="iconos/home_support.png" />}
                        title={formatNumber(props.stats.sales)}
                        subtitle="Equipos reparados"
                    /> 
                </Col>
            </Row>
        </div>
    )
}

export default connect<GeneralDataWidgetInjected>((state: ReduxState) => ({ stats: state.general.stats }))(GeneralDataWidget)
