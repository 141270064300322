import React from 'react'
import { GTagFunction } from './global'

declare const gtag:GTagFunction

export interface GoogleAnalyticsProviderProps {
    trackingId?: string[]
    userId?: string | number
    children: JSX.Element | JSX.Element[]
}

export interface GoogleAnalyticsProviderState {
    context: GoogleAnalyticsContext
}

export interface GoogleAnalyticsContext {
    trackingId: string[]
    gtag: GTagFunction
}

export const GAContext = React.createContext<GoogleAnalyticsContext>(null)

class GoogleAnalyticsProvider extends React.Component<GoogleAnalyticsProviderProps, GoogleAnalyticsProviderState> {

    state = {
        context: {
            trackingId: (process.env.GOOGLE_TRACKING_ID ? process.env.GOOGLE_TRACKING_ID.split(',') : null),
            gtag: gtag
        }
    }

    componentDidMount() {
        const { trackingId } = this.props
        if (trackingId) {
            this.setState({ context: { ...this.state.context, trackingId  } })
        }
        this.evalUserId();
    }

    componentDidUpdate(prevProps: GoogleAnalyticsProviderProps){
        this.evalUserId(prevProps);
    }

    evalUserId(prevProps?: GoogleAnalyticsProviderProps) {
        const { userId } = this.props

        if (userId && (!prevProps || prevProps.userId !== userId)) {
            gtag('set', { 'user_id' : userId.toString()}); 
        }
    }


    render() {
        return (
            <GAContext.Provider value={this.state.context}>
                {this.props.children}
            </GAContext.Provider>
        )
    }
}

export default GoogleAnalyticsProvider
