import './style.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Row, Col, Fade, Container } from 'reactstrap';
import { connect } from 'react-redux';
import ReduxState from '#interfaces/ReduxState';
import { dispatchMap, ThunkDispatchProp } from '#interfaces/ReduxActions';
import { sortByDistance } from '#helpers/utils';
import SucursalComponent from '#components/Sucursal';
import { Office } from '#interfaces/Quotation';
import { getOffices } from '#actions/quotation';

interface SucursalScreenInjected {
    position: { latitude: number, longitude: number }
    offices?: Office[]
}

/**
 * This Login Screen Component implements the login screen
 * @type React.Component
 */
class SucursalScreen extends React.Component<ThunkDispatchProp & SucursalScreenInjected & RouteComponentProps<any>> {

    componentDidMount() {
        const { dispatch } = this.props
        window.scrollTo(0, 0)
        dispatch(getOffices())
    }

    render () {
        const { position, offices } = this.props 
        const sucursales = position ? 
            sortByDistance(offices, position,(sucursal) => sucursal.location) : 
            offices.sort((a, b) => a.name > b.name ? 1 : -1)
        return (
            <div className="screen screen-sucursal">
                <Container>
                    <Fade>
                        <Row>
                            <Col xs={12}>
                                <h1>Sucursales</h1>
                                <h4>{position ? 'Las más cercanas de acuerdo a tu ubicación' : 'Visítanos en cualquiera de nuestras sucursales'}</h4>
                            </Col>
                        </Row>
                        {sucursales.map((sucursal, index) => (
                            <Row key={sucursal.name}>
                                <Col xs={12}>
                                    <hr />
                                </Col>
                                <Col xs={12}>
                                    <SucursalComponent sucursal={sucursal} inverted={!!(index%2)} />
                                </Col>
                            </Row>
                        ))}
                    </Fade>
                </Container>
            </div>
        );
    }
}

export default connect<SucursalScreenInjected>((state: ReduxState) => ({
    position: state.user.position,
    offices: state.quotation.offices
}), dispatchMap)(SucursalScreen)
