import './style.scss'
import React from 'react'
import { Row, Col, Fade, Container } from 'reactstrap'
import ReduxState, { GeoPosition } from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Office, QuotationPageProps, QuotationAddress } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { setSelectedOffice, getOffices } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha, sortByDistance, ArrayDistanceSorted, getNewCurrentPosition } from '#helpers/utils'
import { QuotationPageContext } from '#screens/Quotation'
import CardOfficeButton from '#components/CardOfficeButton'

interface QuotationOfficesInjectedProps {
    offices?: Office[],
    office?: Office
    address?: QuotationAddress
    position?: GeoPosition
}


class QuotationOffices extends React.PureComponent<QuotationPageProps & QuotationOfficesInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null,
	currentPosition: null,
	positionError: "",
	loadingPosition: true,
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
	const { dispatch } = this.props;
        await dispatch(getOffices());
	try {
	  const position = await getNewCurrentPosition();
	  this.setState({ currentPosition: position });
	} catch (error) {
	  this.setState({ positionError: "position error" })
	}
	this.setState({ loadingPosition: false });
    }

    clickOffice = (office: Office) => async () => {
        const { dispatch, onFinish } = this.props
        this.setState({ loading: office.idOffice })
        await dispatch(setSelectedOffice(office))
        onFinish()
    }

    render() {
        const { offices, address, position, office } = this.props
        const { loading, loadingPosition, currentPosition } = this.state
	if (!offices || offices.length <= 0 || loadingPosition) {
	  return (
	    <Container>
	      <Busy busy><div>Cargando...</div></Busy>
	    </Container>
	  );
	}
	const pos = currentPosition || position;
        const officessorted = pos ? sortByDistance<Office>(offices, pos, o => o.location) : sortAlpha<Office>(offices, 'name')
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false }) &&
                    <div className="container-quotationoffices">
                        <Container>
                            <Busy busy={!offices}>
                                <>
                                    <Row>
                                        <Col className="mb-2" xs={12}>
                                            <h4>Selecciona una de las sucursales</h4>
                                            {address && address.zipcode && pos && <i>Se muestran sucursales cercanas al código postal <b>{address.zipcode}</b></i>}
                                            {(!address || !address.zipcode) && pos && <i>Se muestran sucursales ordenadas por cercanía a tu ubicación actual</i>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        {officessorted && officessorted.map(((o: Office & ArrayDistanceSorted) => (
                                            <Col xs={12} sm={6} md={6} lg={4} key={o.idOffice} className="mt-3 office">
                                                <Fade>
                                                    <CardOfficeButton selected={office ? office.idOffice === o.idOffice : undefined} busy={loading === o.idOffice} office={o} onClick={this.clickOffice(o)} />
                                                </Fade>
                                            </Col>
                                        )))}
                                    </Row>
                                </>
                            </Busy>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationOfficesInjectedProps>((state: ReduxState) => ({ 
    offices: state.quotation.offices,
    office: state.quotation.selection.office,
    address: state.quotation.selection.address,
    position: state.quotation.selection.position || state.user.position,
}), dispatchMap)(QuotationOffices)
