import React, { useState, useCallback } from 'react'
import { ConektaForm, ConektaNameInput, ConektaCardInput, ConektaExpMonthInput, ConektaExpYearInput, ConektaCVCInput } from '#helpers/conekta'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCreditCard, faCalendarDay, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { ConektaTokenResponse } from '#helpers/conekta/interfaces'

interface PaymentConektaProps {
    conektaKey: string //process.env.REACT_APP_CONEKTAKEY
    onSubmit: (value: ConektaTokenResponse) => Promise<void>

}

const generateKey = () => `${(new Date()).getTime()}_${Math.random()}`

const PaymentConekta = (props: PaymentConektaProps) => {
    const [resetkey, setResetKey] = useState(generateKey())
    const [busy, setBusy] = useState(false)

    const resetKey = useCallback(() => setResetKey(generateKey()), [])
    const submit = useCallback(async (value: ConektaTokenResponse) => {
        try {
            setBusy(true)
            await props.onSubmit(value)
        } catch(e) {
            resetKey()
            setBusy(false)
            throw e
        }
    }, [props, resetKey])


    return (
        <ConektaForm key={resetkey} publicKey={props.conektaKey} language="es" onSubmit={submit}>
            <Row>
                <Col xs={12} className="mb-3">
                    <h4>Pago con tarjeta de crédito o débito</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <ConektaNameInput placeholder="Ej. Juan Perez" prepend={<FontAwesomeIcon icon={faUser} />} label="Nombre en la tarjeta" />
                </Col>
                <Col xs={12} md={6}>
                    <ConektaCardInput placeholder="####-####-####-####" prepend={<FontAwesomeIcon icon={faCreditCard} />} label="Número de tarjeta de crédito/débito" />
                </Col>
                <Col xs={6} sm={4} md={3} lg={3}>
                    <ConektaExpMonthInput placeholder="MM" prepend={<FontAwesomeIcon icon={faCalendarDay} />} label="Mes de expiración" />
                </Col>
                <Col xs={6} sm={4} md={3} lg={3}>
                    <ConektaExpYearInput placeholder="YY" prepend={<FontAwesomeIcon icon={faCalendar} />} label="Año de expiración" />
                </Col>
                <Col xs={6} sm={4} md={3} lg={3}>
                    <ConektaCVCInput prepend={'***'} label="Código CVC" />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} md={{ size: 4, offset: 8 }} className="d-flex justify-content-center mt-3 mt-md-0">
                    {busy ? <Spinner color="alt-primary" /> : <Button block type="submit" color="primary">Pargar</Button>}
                </Col>
            </Row>
        </ConektaForm>
    )
}

export default PaymentConekta
