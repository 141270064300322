// Dependencies
import { combineReducers, Reducer } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { routerReducer } from 'react-router-redux';
import ReduxState from '#interfaces/ReduxState';

// List of all the Shared reducers
import user from '#reducers/user';
import quotation from '#reducers/quotation'
import repair from '#reducers/repair'
import payment from '#reducers/payment'
import general from '#reducers/general'
import seocontents from '#reducers/seocontents'

const reducer: Reducer<ReduxState> = combineReducers<ReduxState>({
	intl: intlReducer,
	routing: routerReducer,
	quotation,
	user,
	repair,
	payment,
	general,
	seocontents,
});

export default reducer;
