import React, { useCallback } from 'react'
import { PaymentMethodEnum } from '#interfaces/Payment'
import { Row, Col, Spinner } from 'reactstrap'
import CardButton from '#components/CardButton'
import Img from '#components/Image'

interface PaymentSelectProps {
    onSelect: (payment: PaymentMethodEnum) => void
    busy?: boolean
    localonly?: boolean
}

const PaymentSelect = (props: PaymentSelectProps) => {

    const pay = useCallback((payment: PaymentMethodEnum) => () => {
        props.onSelect(payment)
    }, [props])

    return (
        <div className="component-paymentselect">
            <Row className="payment">
                {props.busy && 
                    <Col className="busy">
                        <Spinner color="alt-primary" size="lg" />
                    </Col>
                }
                {props.localonly ?
                    <>
                        <Col xs={12} md={6} lg={{ size: 3, offset: 3 }}>
                            <CardButton name="Pago en efectivo" icon={<Img src="iconos/payment_cash.png" />} onClick={pay(PaymentMethodEnum.CASH)} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <CardButton name="Tarjeta con términal física" icon={<Img src="iconos/payment_terminal.png" />} onClick={pay(PaymentMethodEnum.PHYSICAL_TERMINAL)} />
                        </Col>
                    </>
                    :
                    <>
                        <Col xs={12} md={6} lg={3}>
                            <CardButton name="Paypal" icon={<Img src="iconos/payment_paypal.png" />} onClick={pay(PaymentMethodEnum.PAYPAL)} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <CardButton name="Tarjeta de Crédito o Débito" icon={<Img src="iconos/payment_conekta.png" />} onClick={pay(PaymentMethodEnum.CONEKTA)} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <CardButton name="Pago en efectivo" icon={<Img src="iconos/payment_cash.png" />} onClick={pay(PaymentMethodEnum.CASH)} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <CardButton name="Tarjeta con términal física" icon={<Img src="iconos/payment_terminal.png" />} onClick={pay(PaymentMethodEnum.PHYSICAL_TERMINAL)} />
                        </Col>
                    </>
                }
                
            </Row>
        </div>
    )
}

export default PaymentSelect