import './style.scss'
import React from 'react'
import { Row, Container, Col } from 'reactstrap'
import ReduxState, { QuotationSelection } from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { QuotationPageProps } from '#interfaces/Quotation'
import { QuotationPageContext } from '#screens/Quotation'
import ServiceSummary from '#components/ServiceSummary'
import PaymentBox from '#containers/PaymentBox'
import { createAppointment } from '#actions/quotation'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import { GAEVENT_CATS } from '#constants'

interface QuotationPreviewInjectedProps {
    selection?: QuotationSelection
}


class QuotationPreview extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuotationPreviewInjectedProps & ThunkDispatchProp> {

    state = {
        loading: false,
        busy: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const { selection } = this.props
        if (!selection.appointment) {
            this.createAppointment()
        }
    }

    createAppointment = async () => {
        const { dispatch, selection } = this.props
        await dispatch(createAppointment(
            selection.client,
            selection.office,
            selection.appointmentslot,
            selection.date,
            selection.cart,
            selection.brand,
            selection.address
        ))
        this.props.analytics.gtag('event', 'begin_checkout', { event_category: GAEVENT_CATS.ECOMMERCE })
    }

    onPayed = () => {
        this.props.onFinish()
    }

    render() {
        const { selection } = this.props
        

        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: this.state.loading }) &&
                    <div className="container-quotationpreview">
                        <Container>
                            <Row className="mt-5">
                                <Col xs={12}>
                                    <ServiceSummary selection={selection} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <PaymentBox localonly={!selection.cart.total} onPayed={this.onPayed} />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuotationPreviewInjectedProps>((state: ReduxState) => ({ 
    selection: state.quotation.selection 
}), dispatchMap)(withGoogleAnalytics(QuotationPreview))