import './style.scss'
import React from 'react'
import { Spinner } from 'reactstrap'
import Img from '#components/Image'
import { Office } from '#interfaces/Quotation'
import { ArrayDistanceSorted } from '#helpers/utils'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface CardOfficeButtonProps {
    onClick: (() => void) | (() => Promise<void>)
    busy?: boolean
    alt?: boolean
    selected?: boolean
    office: Office & ArrayDistanceSorted
    
}

const CardOfficeButton = (props: CardOfficeButtonProps) => (
    <button onClick={props.onClick} className={`component-cardofficebutton ${props.alt ? 'alt': ''} ${props.selected ? 'selected': ''}`.trim()}>
        <span className="image"><Img src={props.office.photo || 'sucursales/default.png'} /></span>
        <span className="info">
            {props.office.name && <h5>{props.office.name || ''}</h5>}
	    <div>
	      {props.office.phone && (
		<div className="d-flex flex-row items-center my-2 justify-content-center">
		  <FontAwesomeIcon size="lg" icon={faPhone} color="gray" />
		  <p className="ml-1">{props.office.phone}</p>
		</div>
	      )}
	      {props.office.address && <p className="mb-2">{props.office.address}</p>}
	      {props.office.distance && <i>{(props.office.distance/1000).toFixed(1)}km</i>}
	    </div>
        </span>
        {props.busy && <Spinner size="sm" color="secondary" />}
    </button>
)

export default CardOfficeButton
