import React, { useCallback, useMemo } from 'react'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { connect } from 'react-redux'
import { setSelectedProduct, setSelectedAppointmentOnly, setPage, setSelectedPart, setSelectedModel } from '#actions/quotation'
import { DEFAULT_GENERIC_PRODUCT_ID, DIAGNOSTIC_SERVICE_ID, GAEVENT_CATS } from '#constants'
import { PAGES } from '#screens/Quotation'
import CardButton from '#components/CardButton'
import ReduxState from '#interfaces/ReduxState'
import { Product, Part } from '#interfaces/Quotation'
import Img from '#components/Image'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'

interface GenericPartCartCreationInjected {
    product: Product
    parts: Part[]
}

const GenericPartCartCreation = (props: WithGoogleAnalyticsProps & ThunkDispatchProp & GenericPartCartCreationInjected) => {

    const submit = useCallback(() => {
        const part = props.parts.find(p => p.idPart === DIAGNOSTIC_SERVICE_ID)
        props.dispatch(setSelectedPart(part))
        props.dispatch(setSelectedModel(null))
        props.dispatch(setSelectedProduct({ idProduct: DEFAULT_GENERIC_PRODUCT_ID, name: 'DIAGNOSTICO / VALORACIÓN', slug: 'valoracion-diagnostico' }))
        props.dispatch(setSelectedAppointmentOnly(true))
        props.dispatch(setPage(PAGES.CLIENT))

        props.analytics.gtag('event', 'select_notfound', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: part.name })
    }, [props])

    const productId = useMemo(() => props.product ? props.product.idProduct : null, [props])

    return (
        <CardButton
            icon={<Img src='iconos/part_search.png' />}
            selected={productId === DEFAULT_GENERIC_PRODUCT_ID}
            name={'No encuentro mi dispositivo'}
            onClick={submit}
        />
    )
}

export default connect((state: ReduxState) => ({ 
    product: state.quotation.selection.product,
    parts: state.quotation.parts
}), dispatchMap)(withGoogleAnalytics(GenericPartCartCreation))
