import './style.scss'
import React from 'react'
import { Row, Col, Fade, Container } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Model, QuotationPageProps, Brand, Line } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { setSelectedModel, setSelectedAppointmentOnly } from '#actions/quotation'
import Busy from '#components/Busy'
import { sortAlpha, createQuotationLink } from '#helpers/utils'
import CardButton from '#components/CardButton'
import { QuotationPageContext } from '#screens/Quotation'
import GenericPartCartCreation from '#containers/GenericPartCartCreation'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import { GAEVENT_CATS } from '#constants'

interface QuoationModelsInjectedProps {
    models?: Model[]
    model?: Model
    brand?: Brand
    line?: Line
}


class QuoationModels extends React.PureComponent<WithGoogleAnalyticsProps & QuotationPageProps & QuoationModelsInjectedProps & ThunkDispatchProp> {

    state = {
        loading: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.analytics.gtag('event', 'view_item_list', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: 'models' })
    }

    modelClick = (model: Model) => async () => {
        const { dispatch, onFinish } = this.props
        this.setState({ loading: model.idModel })
        await dispatch(setSelectedModel(model))
        dispatch(setSelectedAppointmentOnly(false))

        this.props.analytics.gtag('event', 'select_item_model', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: model.name })
        onFinish()
    }

    render() {
        const { models, model, brand, line } = this.props
        const { loading } = this.state
        return (
            <QuotationPageContext.Consumer>
                {setContext => setContext({ busy: false, should_submit: false, loading: !!this.state.loading }) &&
                    <div className="container-quotationlines">
                        <Container>
                            <Busy busy={!models}>
                                <>
                                    <Row>
                                        {models && sortAlpha(models, 'name').map(((m: Model) => (
                                            <Col xs={12} sm={6} md={4} lg={3} key={m.idModel} className="mt-3">
                                                <Fade>
                                                    <CardButton 
                                                        image_alt={m.image_alt}
                                                        selected={m.idModel === model.idModel}
                                                        busy={loading === m.idModel}
                                                        name={m.name.split(' ').slice(0,2).join(' ')}
                                                        subname={m.name.split(' ').slice(2).join(' ')}
                                                        onClick={this.modelClick(m)}
                                                        image={m.photo}
                                                        href={createQuotationLink([brand, line, m])}
                                                    />
                                                </Fade>
                                            </Col>
                                        )))}
                                        <Col xs={12} sm={6} md={4} lg={3} className="mt-3">
                                            <GenericPartCartCreation />
                                        </Col>
                                    </Row>
                                </>
                            </Busy>
                        </Container>
                    </div>
                }
            </QuotationPageContext.Consumer>
        )
    }
}

export default connect<QuoationModelsInjectedProps>((state: ReduxState) => ({ 
    models: state.quotation.models,
    model: state.quotation.selection.model || {},
    brand: state.quotation.selection.brand,
    line: state.quotation.selection.line
}), dispatchMap)(withGoogleAnalytics(QuoationModels))