import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions';
import ReduxState, { QuotationState } from '#interfaces/ReduxState';
import { Row, Col, Container, Fade, Button, Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap';
import QuotationBrands from '#containers/QuotationBrands';
import QuotationLines from '#containers/QuotationLines';
import QuotationModels from '#containers/QuotationModels';
import QuotationParts from '#containers/QuotationParts';
import QuotationProducts from '#containers/QuotationProducts';
import QuotationCities from '#containers/QuotationCities';
import QuotationOffices from '#containers/QuotationOffices';
import QuotationAppointmentSlots from '#containers/QuotationAppointmentSlots';
import QuotationClient from '#containers/QuotationClient';
import QuotationPreview from '#containers/QuotationPreview';
import QuotationFinal from '#containers/QuotationFinal';
import { ROUTES, QUOTATION_AVAILABLE_TYPES, SYNTH_SERVICES, DEFAULT_GENERIC_PRODUCT_ID } from '#constants';
import { setPage, resetQuotation, setQuotationType } from '#actions/quotation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { parseProduct, createSlug } from '#helpers/utils';
import QuotationType from '#containers/QuotationType';
import { QuotationTypeCatalog } from '#interfaces/Quotation';
import Busy from '#components/Busy';
import StepperBar from '#components/StepperBar';
import TitleBar from '#components/TitleBar';
import MaterialIcon from 'material-icons-react';
import Img from '#components/Image';
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics';


interface QuotationInjectedProps {
    quotation: QuotationState
}

interface QuotationFormState {
    should_submit?: boolean
    loading?: boolean
    busy: boolean
    global_loading?: boolean

}

interface QuotationFormContextInfo extends QuotationFormState {}

export const PAGES = {
    BRANDS: 0,
    LINES: 1,
    MODELS: 2,
    PARTS: 3,
    PRODUCTS: 4,
    CLIENT: 6,
    TYPE: 5,
    CITIES: 7,
    OFFICES: 8,
    ASLOTS: 9,
    PREVIEW: 10,
    FINAL: 11
}

export const QuotationPageContext = React.createContext<(context: QuotationFormContextInfo) => boolean>(null)

/**
 * This Login Screen Component implements the login screen
 * @type React.Component
 */
class Quotation extends React.Component<WithGoogleAnalyticsProps & QuotationInjectedProps & ThunkDispatchProp & RouteComponentProps<any>, QuotationFormState> {

    state = {
        should_submit: false,
        loading: false,
        busy: false,
        global_loading: false
    }

    async componentDidMount() {
        const { quotation, history } = this.props
        const { search } = history.location
	if (quotation.selection.client) {
            history.push(`${ROUTES.QUOTE}/${this.setPageName(quotation.page)}${search||''}`)
            if (quotation && quotation.selection && quotation.selection.cart) {
                this.goTo(PAGES.PREVIEW)
            }
	}
    }

    getSteps = () => {
        return [
	    { title: 'Dispositivo', onClick: this.linkToBrands },
            { title: 'Servicio ó Producto', onClick: this.linkToParts },
            { title: 'Tus datos', onClick: this.barGoTo(PAGES.CLIENT) },
            { title: 'Tu cita', onClick: this.barGoTo(PAGES.ASLOTS) },
            { title: 'Confirmación' },
            { title: '¡Listo!' }
        ]
    }

    linkToBrands = () => {
	const {quotation} = this.props;
	const page = quotation.page;
	if (PAGES.BRANDS < page) {
	  const selectedBrand = quotation.selection.brand ? quotation.selection.brand.slug : "";
	  window.location.href = `${process.env.PUBLIC_URL}${ROUTES.QUOTE}/${selectedBrand}`;
	}
    }

    linkToParts = () => {
	const {quotation} = this.props;
	const page = quotation.page;
	if (PAGES.PARTS < page) {
	  const selectedBrand = quotation.selection.brand ? quotation.selection.brand.slug : "";
	  const selectedLine = quotation.selection.line ? quotation.selection.line.slug : "";
	  const selectedModel = quotation.selection.model ? quotation.selection.model.slug : "";
	  const selectedPart = quotation.selection.part ? `/${quotation.selection.part.slug}` : "";
	  window.location.href = `${process.env.PUBLIC_URL}${ROUTES.QUOTE}/${selectedBrand}/${selectedLine}/${selectedModel}${selectedPart}`;
	}
    }

    getSubtitle(page: number): { subtitle: string, icon: React.ReactNode } {
        switch(page) {
            case PAGES.BRANDS:
                return { subtitle: 'Selecciona la marca de tu dispositivo', icon: <Img size={26} src="iconos/step_broken_cell.png" /> }
            case PAGES.LINES:
                return { subtitle: 'Selecciona la linea de tu dispositivo', icon: <Img size={26} src="iconos/step_broken_cell.png" /> }
            case PAGES.MODELS:
                return { subtitle: 'Selecciona el modelo de tu dispositivo', icon: <Img size={26} src="iconos/step_broken_cell.png" /> }
            case PAGES.PARTS:
                return { subtitle: 'Selecciona la parte o servicio que necesitas', icon: <Img size={26} src="iconos/step_part.png" /> }
            case PAGES.PRODUCTS:
                return { subtitle: 'Selecciona un producto', icon: <Img size={26} src="iconos/step_part.png" /> }
            case PAGES.CLIENT:
                return { subtitle: 'Antes de seguir, necesitamos un poco de información sobre tí', icon: <Img size={26} src="iconos/step_client.png" /> }
            case PAGES.TYPE:
                return { subtitle: 'Selecciona el tipo de servicio', icon: <Img size={26} src="iconos/step_client.png" /> }
            case PAGES.CITIES:
                return { subtitle: 'Dirección a donde enviaremos a nuestro técnico', icon: <Img size={26} src="iconos/step_client.png" /> }
            case PAGES.OFFICES:
                return { subtitle: 'Selecciona una sucursal', icon: <Img size={26} src="iconos/step_client.png" /> }
            case PAGES.ASLOTS:
                return { subtitle: 'Selecciona un espacio para tu cita', icon: <Img size={26} src="iconos/step_client.png" /> }
            case PAGES.PREVIEW:
                return { subtitle: 'Revisa tu información, y si todo está bien, selecciona una forma de pago para continuar', icon: <Img size={26} src="iconos/step_confirm.png" /> }
            case PAGES.FINAL:
                return { subtitle: '', icon: <Img size={26} src="iconos/step_confirm.png" /> }
        }
    }


    goBack = () => {
        const { quotation: { page }, dispatch, history } = this.props
        
        if (page > 0) {
            const nextpage = page - 1
            const realpage = this.exceptions(nextpage, -1)
            this.setState({ loading: false, should_submit: false })
            dispatch(setPage(realpage))
            history.push(`${ROUTES.QUOTE}/${this.setPageName(realpage)}`)
        }
        
    }

    next = (e) => {
        const { quotation: { page }, dispatch, history } = this.props
        if(e && e.stopPropagation) e.stopPropagation();
        if(e && e.stopPropagation) e.preventDefault();

        if (page < PAGES.FINAL) {
            const nextpage = page + 1
            const realpage = this.exceptions(nextpage, 1)
            this.setState({ busy: false, loading: false, should_submit: false })
            dispatch(setPage(realpage))
            history.push(`${ROUTES.QUOTE}/${this.setPageName(realpage)}`)
        }
        
    }

    exceptions (nextpage: number, direction: (1 | -1)): number {
        const { quotation: { selection: { type, appointment_only, model, part, product } }, dispatch,  } = this.props
        switch(nextpage) {
            case PAGES.PRODUCTS:
                if (appointment_only && (!product || product.idProduct === DEFAULT_GENERIC_PRODUCT_ID)) {
                    return this.exceptions(nextpage + direction, direction)
                }
                return nextpage
            case PAGES.PARTS:
                if (appointment_only && !part) {
                    return this.exceptions(nextpage + direction, direction)
                }
                return nextpage
            case PAGES.MODELS:
                if (appointment_only && !model) {
                    return this.exceptions(nextpage + direction, direction)
                }
                return nextpage
            case PAGES.CITIES: {
                if (type === QuotationTypeCatalog.HOME_SERVICE) {
                    return nextpage;
                }
                return nextpage + direction;
            }
            case PAGES.OFFICES:
                if (type === QuotationTypeCatalog.HOME_SERVICE) {
                    return nextpage + direction
                }
                return nextpage
            case PAGES.TYPE:
                if (QUOTATION_AVAILABLE_TYPES.length === 1) {
                    dispatch(setQuotationType(QUOTATION_AVAILABLE_TYPES[0]))
                    return nextpage + direction
                }
                return nextpage
            default: 
                return nextpage
        }
    }

    getPageAvailability(quotation: QuotationState) {
        
        if (!quotation.selection.appointment_only && !quotation.lines) return PAGES.BRANDS // SHOW BRANDS
        if (!quotation.selection.appointment_only && !quotation.models) return PAGES.LINES // SHOW LINES
        if (!quotation.selection.appointment_only && !quotation.selection.model) return PAGES.MODELS //SHOW MODELS
        if (!quotation.selection.appointment_only && !quotation.selection.part) return PAGES.PARTS //SHOW PARTS
        if (!quotation.selection.appointment_only && !quotation.selection.product) return PAGES.PRODUCTS // SHOW PRODUCTS
        if (!quotation.selection.client) return PAGES.CLIENT // SHOW CREATE CLIENT
        if (!quotation.selection.type) return PAGES.TYPE // SELECT SERVICE TYPE
        if (!quotation.offices) return PAGES.CITIES // SHOW CITIES
        if (!quotation.selection.office) return PAGES.OFFICES //SHOW OFFICES
        if (quotation.selection.office && !quotation.selection.appointmentslot) return PAGES.ASLOTS // SHOW APPOINTMENT SLOTS
        if (quotation.selection.appointmentslot && !quotation.selection.payed) return PAGES.PREVIEW // SHOW PREVIEW & PAY
        if (quotation.selection.payed) return PAGES.FINAL // SHOW FINAL SCREEN
        return 1
    }

    

    setPageName(page: number) {
        const { quotation } = this.props
        
        switch(page) {
            case PAGES.LINES:
                return createSlug(quotation.selection.brand)
                
            case PAGES.MODELS:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}`
                
            case PAGES.PARTS:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}${createSlug(quotation.selection.model)}`
            case PAGES.PRODUCTS:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}${createSlug(quotation.selection.model)}${createSlug(quotation.selection.part)}`
            case PAGES.CLIENT:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}${createSlug(quotation.selection.model)}${createSlug(quotation.selection.part)}${createSlug(quotation.selection.product)}`
            case PAGES.TYPE:
            case PAGES.CITIES:
            case PAGES.OFFICES:
            case PAGES.ASLOTS:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}${createSlug(quotation.selection.model)}${createSlug(quotation.selection.part)}${createSlug(quotation.selection.product)}lugar-y-hora`
            case PAGES.PREVIEW:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}${createSlug(quotation.selection.model)}${createSlug(quotation.selection.part)}${createSlug(quotation.selection.product)}preview`
            case PAGES.FINAL:
                return `${createSlug(quotation.selection.brand)}${createSlug(quotation.selection.line)}${createSlug(quotation.selection.model)}${createSlug(quotation.selection.part)}${createSlug(quotation.selection.product)}final`
            default:
                return ''
        }
    }


    getStep(section: number) {
        switch(section) {
            case PAGES.BRANDS:
            case PAGES.LINES:
            case PAGES.MODELS:
                return 0
            case PAGES.PARTS:
            case PAGES.PRODUCTS:
                return 1
            case PAGES.CLIENT:
                return 2
            case PAGES.TYPE:
            case PAGES.CITIES:
            case PAGES.OFFICES:
            case PAGES.ASLOTS:
                return 3
            case PAGES.PREVIEW:
                return 4
            case PAGES.FINAL:
                return 5
        }
    }

    goHome = () => {
        const { history } = this.props
        history.replace(ROUTES.ROOT)
    }

    goTo = (page: number) => () => {
        const { quotation: { page: currentPage }, history } = this.props
        const direction = page > currentPage ? 1 : -1
        this.props.dispatch(setPage(this.exceptions(page, direction)))
        history.push(`${ROUTES.QUOTE}/${this.setPageName(page)}`)
    }

    barGoTo = (page: number) => () => {
        const { quotation: { page: currentPage }, history } = this.props
        if (page < currentPage) {
            const direction = page > currentPage ? 1 : -1
            this.props.dispatch(setPage(this.exceptions(page, direction)))
            history.push(`${ROUTES.QUOTE}/${this.setPageName(page)}`)
        }
    }

    finish = () => {
        const { history, dispatch } = this.props
        dispatch(resetQuotation())
        history.replace(ROUTES.ROOT)
    }

    setContext = (information: QuotationFormContextInfo): boolean => {
        this.setState({ ...information })
        return true 
    }


    render () {
        const { quotation } = this.props
        const { loading, global_loading } = this.state
        const { page } = quotation
        const step = this.getStep(page)
	const baseUrl = `${process.env.PUBLIC_URL}/cotizar/reparacion`;
	const selectedBrand = quotation.selection.brand ? quotation.selection.brand.slug : "";
	const selectedLine = quotation.selection.line ? quotation.selection.line.slug : "";
	const selectedModel = quotation.selection.model ? quotation.selection.model.slug : "";
	const selectedPart = quotation.selection.part ? quotation.selection.part.slug : "";
	const selectedProduct = quotation.selection.product ? quotation.selection.product.slug : "";
	const isCitiesOrOfficesAndLocalService = quotation.selection.type === QuotationTypeCatalog.HOME_SERVICE || (quotation.page !== PAGES.CITIES && quotation.page !== PAGES.OFFICES);
	const goBackUrl = () => {
	  if (selectedProduct) {
	    return `${baseUrl}/${selectedBrand}/${selectedLine}/${selectedModel}/${selectedPart}`;
	  } else if (selectedPart) {
	    return `${baseUrl}/${selectedBrand}/${selectedLine}/${selectedModel}`;
	  } else if (selectedModel) {
	    return `${baseUrl}/${selectedBrand}/${selectedLine}`;
	  } else if (selectedLine) {
	    return `${baseUrl}/${selectedBrand}`;
	  }
	  return `${baseUrl}`;
	};

        return (
            <div className="screen screen-quotation">
                <Container>
                    <Busy busy={global_loading}>
                        {page !== PAGES.FINAL && 
                            <Row>
                                <Col className="d-none d-md-flex mt-2" xs={12}>
                                    <div className="breadcrumb d-flex flex-grow-1 justify-content-between">
				      {quotation.page > PAGES.BRANDS && quotation.page <= PAGES.CLIENT && <a className="back-anchor" href={goBackUrl()}><Button color="link" ><MaterialIcon icon="keyboard_arrow_left" /> Regresar</Button></a>}
				      {quotation.page > PAGES.CLIENT && quotation.page < PAGES.FINAL && <Button onClick={this.goBack} color="link" ><MaterialIcon icon="keyboard_arrow_left" /> Regresar</Button>}
                                        <Breadcrumb className="dark-breadcrumb">
                                            {quotation.selection.brand && <BreadcrumbItem active={page === PAGES.BRANDS}><a href={`${baseUrl}/${selectedBrand}`}>{quotation.selection.brand.name.toLowerCase()}</a></BreadcrumbItem>}
                                            {quotation.selection.line && <BreadcrumbItem active={page === PAGES.LINES}><a href={`${baseUrl}/${selectedBrand}/${selectedLine}`}>{quotation.selection.line.name.toLowerCase()}</a></BreadcrumbItem>}
                                            {quotation.selection.model && <BreadcrumbItem active={page === PAGES.MODELS}><a href={`${baseUrl}/${selectedBrand}/${selectedLine}/${selectedModel}`}>{quotation.selection.model.name.toLowerCase().split(' ').slice(0,2).join(' ')}</a></BreadcrumbItem>}
                                            {quotation.selection.part && <BreadcrumbItem active={page === PAGES.PARTS}><a href={`${baseUrl}/${selectedBrand}/${selectedLine}/${selectedModel}/${selectedPart}`}>{quotation.selection.part.name.toLowerCase()}</a></BreadcrumbItem>}
                                            {quotation.selection.product && (!quotation.selection.part || quotation.selection.part.idPart !== SYNTH_SERVICES.DIAGNOSTIC.idPart) && <BreadcrumbItem active={page === PAGES.PRODUCTS}><a href={`${baseUrl}/${selectedBrand}/${selectedLine}/${selectedModel}/${selectedPart}/${selectedProduct}`}>{parseProduct(quotation.selection.product).name.toLowerCase()}</a></BreadcrumbItem>}
                                        </Breadcrumb>
                                    </div>
                                </Col>
                                <Col className="d-flex d-md-none mt-2" xs={12}>
                                    <div className="breadcrumb d-flex flex-grow-1 justify-content-between">
                                        {quotation.page < PAGES.FINAL && <Button onClick={this.goBack} color="link" ><MaterialIcon icon="keyboard_arrow_left" /> Regresar</Button>}
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col className="stepper" xs={12}>
                                <StepperBar
                                    steps={this.getSteps()}
                                    activeStep={step}
                                    disableClicks={page === PAGES.FINAL}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="title mt-3" xs={12}>
                                <TitleBar title={this.getSteps()[step].title} subtitle={this.getSubtitle(page).subtitle} icon={this.getSubtitle(page).icon} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="content" xs={12}>
                                <QuotationPageContext.Provider value={this.setContext}>
                                    {page === PAGES.BRANDS && <Fade><QuotationBrands onFinish={this.next}/></Fade>}
                                    {page === PAGES.LINES && <Fade><QuotationLines onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.MODELS && <Fade><QuotationModels onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.PARTS && <Fade><QuotationParts onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.PRODUCTS && <Fade><QuotationProducts onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.TYPE && <Fade><QuotationType onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.CITIES && <Fade><QuotationCities onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.OFFICES && <Fade><QuotationOffices onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.ASLOTS && <Fade><QuotationAppointmentSlots onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.CLIENT && <Fade><QuotationClient onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.PREVIEW && <Fade><QuotationPreview onBack={this.goBack} onFinish={this.next}/></Fade>}
                                    {page === PAGES.FINAL && <Fade><QuotationFinal onFinish={this.finish}/></Fade>}
                                </QuotationPageContext.Provider>
                            </Col>
                        </Row>
                        <Container>
                            <Row className="controls">
                                <Col className="d-flex justify-content-center" xs={12} md={{ size: 4, offset: 8 }}>
                                    {loading ? <Spinner color="secondary" /> :
                                        <>
                                        {quotation.page < PAGES.FINAL && isCitiesOrOfficesAndLocalService &&
                                            <> 
                                            {this.state.should_submit &&
                                                <Button disabled={loading} className="btn-flat" block color="primary" type="submit" form="quotation-sequence-form">
                                                    {'Siguiente '}
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                </Button>
                                            }
                                            </>
                                        }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Busy>
                </Container>
            </div>
        );
    }
}

export default connect<QuotationInjectedProps>((state: ReduxState) => ({ 
    quotation: state.quotation
}), dispatchMap)(withGoogleAnalytics(withRouter(Quotation)));
