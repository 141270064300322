import { GeoPosition } from "./ReduxState";

export enum QuotationTypeCatalog {
    LOCAL_SERVICE=1, HOME_SERVICE = 2
}


export interface QuotationAddress {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zipcode?: string
    extrainformation?: string
    position?: { lat: number, lng: number }
}

export interface QuotationPaymentMethod {
    id?: string
    name?: string
}

export interface QuotationPageProps {
    onFinish: (nextpage?: number) => void
    onBack?: () => void
    onWorking?: (state: boolean) => void
}

export interface Brand {
    idBrand?: number
    name?: string
    abbreviation?: string
    slug?: string
    photo?: string
    image_alt?: string
}

export interface Line {
    idLine?: number
    name?: string
    abbreviation?: string
    brand?: Brand
    slug?: string
    photo?: string
    image_alt?: string
}

export interface Model {
    idModel?: number
    name?: string
    abbreviation?: string
    line?: Line
    photo?: string
    slug?: string
    image_alt?: string
}

export interface Part {
    idPart?: number
    name?: string
    abbreviation?: string
    slug?: string
    photo?: string
    image_alt?: string
}

export interface Price {
    idPrice?: number
    name?: string
    price?: number
}

export interface Variation {
    idVartiation?: number
    name?: string
    abbreviation?: string
    value?: string
}

export interface Product {
    idProduct?: number
    name?: string
    code?: string
    barcode?: string
    isservice?: number
    model?: Model
    line?: Line
    brand?: Brand
    variation?: Variation
    version?: string
    prices?: Price[]
    part?: Part
    photo?: string
    image_alt?: string
    slug?: string
}

export interface QuotationCity {
    idCity?: number
    name?: string
}

export interface Cart {
    id?: string
    product?: string
    quantity?: string
    subtotal?: number
    tax?: number
    total?: number
    photo?: string
}

export interface CartSummary {
    id?: string
    created?: string
    idcartstatus?: number
    status?: string
    reference?: string
    subtotal?: number
    tax?: number
    total?: number
    cart?: Cart[]
}

export interface Client {
    idClient?: number
    firstname?: string
    lastname?: string
    email?: string
    phone?: string
    cellphone?: string
    promotionsmail?: number
    servicesmail?: number
}

export interface CartRequest {
    idProduct: number
    quantity: number
}

export interface AppointmentSlot {
    idAppointmentslot?: number
    label?: string
    start?: string
    end?: string
}

export interface Office {
    idOffice?: number
    name?: string
    homeservice?: number
    fieldservice?: number
    service?: number
    city?: QuotationCity
    address?: string
    image?: string
    schedule?: string
    location?: GeoPosition
    place?: string
    phone?: string
    photo?: string
}

export interface Timestamp {
    date?: string
    timezone_type?: number
    timezone?: string
}

export interface Appointment {
    start?: string
    end?: string
    creationTime?: Timestamp
    enabled?: number
    appointment_external_id?: string
    idAppointmentslot?: string
    idCart?: number
    idClient?: number
    idOffice?: number
    title?: string
    description?: string
    idCalendar?: number
    barcode?: string
    folio?: string
}

export interface APIResponse<T> {
    success: string,
    message: string, 
    data: T
}

export interface Sale {
    cartreference: string,
    date: string,
    paid: number,
    products: Array<Cart>,
    reference: string,
    status: string,
    subtotal: number,
    tax: number,
    total: number,
}


export const addressPlanify = (address: QuotationAddress): string => address ? (
    `${address.address1}${address.address2 ? ` ${address.address2}` : ''}, ${address.city}, C.P. ${address.zipcode}`
) : "";

export const fullname = (client: Client): string => (
    `${client.firstname} ${client.lastname}`
)

export const findPriceId = (prices: Price[], id: number): number => {
    if (prices) {
        const p = prices.find(p => p.idPrice === id)
        if (p) {
            return p.price || 0
        }
    }
    return null
}
