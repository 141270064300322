import React from 'react'
import { GAContext } from './GoogleAnalyticsProvider'
import { GTagFunction } from './global'

export interface WithGoogleAnalyticsProps {
    analytics: {
        gtag: GTagFunction
        trackingId: string[]
    }
}

function withGoogleAnalytics <P=any>(Component: React.ComponentType<P & WithGoogleAnalyticsProps>) {
    return (props: P) => {
        return (
            <GAContext.Consumer>
                {context => <Component {...props} analytics={context} />}
            </GAContext.Consumer>
        )
    }
} 

export default withGoogleAnalytics
