import { APIResponse, CartSummary } from '#interfaces/Quotation';
import { PaymentState } from '#interfaces/ReduxState';
import { ReduxAction } from '#interfaces/ReduxActions';
import { ActionCreator } from 'redux';
import APIClient from '#helpers/APIClient';
import { PaymentMethodEnum, PaymentCharge, PaypalPayment, PaypalURL, ConektaPayment } from '#interfaces/Payment';
import { ROUTES, PAYPAL_FAIL_FLAG } from '#constants';


export const TYPE_SET_PAYPAL_URL = '@Payment/SET_PAYPAL_URL';
export const TYPE_SET_PREVIEW_PAYMENT = '@Payment/SET_PREVIEW_PAYMENT';
export const TYPE_SET_PAYMENTMETHOD = '@Payment/SET_PAYMENTMETHOD';
export const TYPE_SET_PAYPALDATA = '@Payment/SET_PAYPALDATA';
export const TYPE_SET_CONEKTADATA = '@Payment/SET_CONEKTADATA';
export const TYPE_RESET_PAYMENT = '@Payment/TYPE_RESET_PAYMENT';

export const setPaypalUrl:ActionCreator<ReduxAction<PaymentState>> = (approve_url: PaypalURL) => {
    return {
        type: TYPE_SET_PAYPAL_URL,
        value: { paypal: { approve_url } }
    }
}

export const setPreviewPayment:ActionCreator<ReduxAction<PaymentState>> = (preview: PaymentCharge) => {
    return {
        type: TYPE_SET_PREVIEW_PAYMENT,
        value: { preview }
    }
}

export const setSelectedPaymentMethod:ActionCreator<ReduxAction<PaymentState>> = (payment_method: PaymentMethodEnum) => {
    return {
        type: TYPE_SET_PAYMENTMETHOD,
        value: { payment_method }
    }
}

export const setPaypalData:ActionCreator<ReduxAction<PaymentState>> = (paypal: PaypalPayment) => {
    return {
        type: TYPE_SET_PAYPALDATA,
        value: { paypal }
    }
}

export const setConektaData:ActionCreator<ReduxAction<PaymentState>> = (conekta: ConektaPayment) => {
    return {
        type: TYPE_SET_CONEKTADATA,
        value: { conekta }
    }
}

export const resetPayment:ActionCreator<ReduxAction<PaymentState>> = () => {
    return {
        type: TYPE_RESET_PAYMENT,
        value: {}
    }
}


export const getPreviewPayment = (cart: CartSummary) => async (dispatch): Promise<void> => {
    const response = await APIClient.get().get(`/pagosPaypal/operation:getCartDiscountPreview/idCart:${cart.id}`)
    const body = await response.json() as APIResponse<PaypalPayment>
    dispatch(setPreviewPayment(body.data.charge))
}

export const createPaypalOrder = (cart: CartSummary) => async (dispatch): Promise<void> => {
    const response = await APIClient.get().post(`/pagosPaypal/operation:createCartDiscount/idCart:${cart.id}`, {
        onApprovedUrl: `${process.env.REACT_APP_PUBLIC_HOSTNAME}${ROUTES.QUOTE}`,
        onCancelUrl: `${process.env.REACT_APP_PUBLIC_HOSTNAME}${ROUTES.QUOTE}?${PAYPAL_FAIL_FLAG}=true`
    })
    const body = await response.json() as APIResponse<PaypalPayment>
    dispatch(setPaypalUrl(body.data.approve_url))
}

export const payConekta = (cart: CartSummary, cardToken: string) => async (dispatch): Promise<void> => {
    const response = await APIClient.get().post(`/pagosConekta/operation:createCartDiscount/idCart:${cart.id}`, {
        cardToken
    })
    const body = await response.json() as APIResponse<ConektaPayment>
    dispatch(setConektaData(body.data))
}



export const confirmPaypal = (paypalOrderId: string) => async (dispatch): Promise<void> => {
    const response = await APIClient.get().post(`/pagosPaypal/operation:confirmCartDiscount/idPaypalOrder:${paypalOrderId}`, {})
    const body = await response.json() as APIResponse<PaypalPayment>
    dispatch(setPaypalData(body.data))
    
}
