import './style.scss'
import React from 'react'
import { Row, Col, Fade } from 'reactstrap'
import ReduxState from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { Brand } from '#interfaces/Quotation'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import Busy from '#components/Busy'
import CardButton from '#components/CardButton'
import { resetPayment } from '#actions/payment'
import { createQuotationLink } from '#helpers/utils'
import { GAEVENT_CATS } from '#constants'
import withGoogleAnalytics, { WithGoogleAnalyticsProps } from '#helpers/GoogleAnalytics'
import {getInitialStates, resetQuotation} from '#actions/quotation'

interface QuotationWidgetInjectedProps {
    brands?: Brand[]
}

interface QuotationWidgetProps {
    onBrand: () => void
}

class QuotationWidget extends React.PureComponent<QuotationWidgetInjectedProps & QuotationWidgetProps & ThunkDispatchProp & WithGoogleAnalyticsProps> {

    state = {
        loading: null
    }

    componentDidMount() {
        this.props.dispatch(resetQuotation())
        this.props.dispatch(resetPayment())
        this.props.dispatch(getInitialStates())
    }

    brandClick = (brand: Brand) => async () => {
        const { analytics: { gtag } } = this.props
        // const { dispatch } = this.props
        // this.setState({ loading: brand.idBrand })
        // await dispatch(setSelectedBrand(brand))
        // await dispatch(getLines(brand.idBrand))
        // dispatch(setPage(PAGES.LINES))
        // this.props.onBrand()
        gtag('event', 'select_item_brand', { event_category: GAEVENT_CATS.ENGAGEMENT, event_label: brand.name })
    }

    render() {
        const { brands } = this.props
        const { loading } = this.state
        return (
            <div className="container-quotationwidget">
                <Row>
                    <Busy busy={!brands}>
                        <Col>
                            <Row>
                                {brands && brands.slice(0,6).map((brand => (
                                    <Col xs={12} md={4} lg={3} key={brand.idBrand} className="mt-3">
                                        <Fade>
                                            <CardButton fixed image_alt={brand.image_alt} image={brand.photo} busy={loading === brand.idBrand} onClick={this.brandClick(brand)} href={createQuotationLink([brand])} />
                                        </Fade>
                                    </Col>
                                )))}
                                <Col xs={12} md={4} lg={3} className="mt-3">
                                    <CardButton alt icon={<h3>Otro</h3>}  onClick={() => this.props.onBrand()} />
                                </Col>
                            </Row>
                        </Col>
                    </Busy>
                </Row>
            </div>
        )
    }
}

export default connect<QuotationWidgetInjectedProps, {}, QuotationWidgetProps>((state: ReduxState) => ({ 
    brands: state.quotation.brands 
}), dispatchMap)(withGoogleAnalytics(QuotationWidget))
