import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { injectIntl, intlShape, InjectedIntlProps } from "react-intl";
import { changeLanguage } from "#actions/intl";
import { ROUTES } from "#constants";

// SCREEN COMPONENTS ---- >
import Home from "#screens/Home";
// -------------

// Main Style
import "#theme/style.scss";
import ReduxState, { UserState } from "#interfaces/ReduxState";
import { LayoutContext, ValidatorContext } from "react-io-forms";
import MainBar from "#containers/MainBar";
import Footer from "#containers/Footer";
import { setUserPosition, setSession } from "#actions/user";
// import APIClient from '#helpers/APIClient';
import Quotation from "#screens/Quotation";
import APIClient from "#helpers/APIClient";
import { dispatchMap, ThunkDispatchProp } from "#interfaces/ReduxActions";
import Busy from "#components/Busy";
import Repair from "#screens/Repair";
import Sucursal from "#screens/Sucursal";
import GoogleMapsAPI from "#helpers/maps/GoogleMapsAPI";
import Payment from "#screens/Payment";
import GoogleAnalyticsProvider from "#helpers/GoogleAnalytics/GoogleAnalyticsProvider";
import PageViewListener from "#helpers/GoogleAnalytics/PageViewListener";
import ButtonWhatsapp from "#components/ButtonWhatsap";
import SEOBanner from "#components/SEOBanner";

interface AppPropsInjected {
  user: UserState;
}
interface MyComponentState {
  start: boolean;
}

/** Root React Component
 * @type {React.Component}
 */
class App extends React.Component<
  InjectedIntlProps & ThunkDispatchProp & AppPropsInjected,
  MyComponentState
> {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  state = {
    start: false,
  };

  async componentDidMount() {
    const { dispatch, user } = this.props;

    // GEOLOCATION ASK
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          setUserPosition(position.coords.latitude, position.coords.longitude)
        );
      });
    }
    /////////////////

    // GOOGLE API INIT
    GoogleMapsAPI.get(process.env.REACT_APP_GOOGLE_API_KEY);

    // API CLIENT SESSION SETUP
    APIClient.get().setToken(user.session);
    APIClient.get().onSessionChange((session) => dispatch(setSession(session)));
    if (!user.session) {
      await APIClient.get().beginSession(null);
    }
    this.setState({ start: true });
    //////////////////

    // INITIAL STATES
    // dispatch(getInitialStates())
  }

  setLanguage(lang: string) {
    this.props.dispatch(changeLanguage(lang));
  }

  render() {
    const {
      intl: { locale },
    } = this.props;
    const { start } = this.state;
    console.log("load application.....");
    return (
      /** By putting the locale as key, the DOM Tree bellow is forced to rerender an replace all intl labels */
      <div className="app" key={locale}>
        <GoogleAnalyticsProvider
          trackingId={process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID.split(
            ","
          )}
        >
          <LayoutContext layouter="Bootstrap4">
            <Busy busy={!start}>
              <ValidatorContext
                validations={{}}
                defaultMessage="Formato Inválido"
                requiredMessage="El campo es obligatorio"
              >
                {start && (
                  <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <>
                      <PageViewListener />
                      <MainBar />
                      <Switch>
                        <Route path={ROUTES.ROOT} exact component={Home} />
                        <Route path={ROUTES.SHOPS} exact component={Sucursal} />
                        <Route
                          path={ROUTES.PAYMENT}
                          exact
                          component={Payment}
                        />
                        <Route
                          path={ROUTES.MYREPAIR}
                          exact
                          component={Repair}
                        />
                        <Route path={ROUTES.QUOTE} component={Quotation} />
                      </Switch>
                      <SEOBanner />
                      <Footer />
                    </>
                  </BrowserRouter>
                )}
              </ValidatorContext>
            </Busy>
          </LayoutContext>
        </GoogleAnalyticsProvider>
        <ButtonWhatsapp line={process.env.REACT_APP_WHATSAPP_LINE} />
      </div>
    );
  }
}

export default connect<AppPropsInjected>((state: ReduxState) => {
  const { intl, user } = state;
  return { intl, user }; // Intl Object from Store gets injected into the App's Props
}, dispatchMap)(injectIntl(App));
