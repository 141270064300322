import React, { useState, useMemo } from "react";
import { Row, Col, Container } from "reactstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
// import ReactMarkdown from "react-markdown";
import style from "./style.module.scss";
import Markdown from "markdown-to-jsx";
import {connect} from "react-redux";
import ReduxState from "#interfaces/ReduxState";

type SEOTabs = {
  id?: number;
  filter?: string;
  title?: string;
  content?: string;
  photo?: string;
};

const getId = (seocontents?: SEOTabs[]) => seocontents && seocontents.length > 0 ? seocontents[0].id : 0;
const SEOBanner = ({ seocontens }: { seocontens: SEOTabs[] }) => {
  const [activeTab, setActiveTab] = useState(getId(seocontens));
  const tabContent = useMemo(() => {
    if (!seocontens) {
      return {};
    }
    return seocontens.find((t) => t.id === activeTab);
  }, [activeTab, seocontens]);

  if (!seocontens) {
    return null;
  }

  return (
    <Container className={`${style.banner}`}>
      <Row>
        {seocontens.map((t) => {
          const isActive = t.id === activeTab;
          return (
            <Col key={t.id} lg="auto">
              <div className="d-flex align-items-center">
                <button
                  className={`${style.button} my-2`}
                  onClick={() => setActiveTab(t.id)}
                >
                  {t.title}{" "}
                  {isActive ? (
                    <MdKeyboardArrowUp size={24} />
                  ) : (
                    <MdKeyboardArrowDown size={24} />
                  )}
                </button>
              </div>
            </Col>
          );
        })}
      </Row>
      {tabContent && Boolean(tabContent.content) && (
        <>
        {
          tabContent.photo && (
            <Row className="mt-5">
              <Col><img src={tabContent.photo} alt="seo-content" /></Col>
            </Row>
          )
        }
        <Row className="mt-3">
          <Col>
            <Markdown>{tabContent.content}</Markdown>
          </Col>
        </Row>
        </>
      )}
    </Container>
  );
};

export default connect((state: ReduxState) => ({ seocontens: state.seocontents.data }))(SEOBanner);
