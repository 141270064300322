import React, { useCallback, useEffect } from 'react'
import { ConektaCardParams, ConektaTokenParams, ConektaAPI, ConektaTokenResponse, ConektaToken } from './interfaces'
import IOForm, { IOFormProps } from 'react-io-forms'

declare const Conekta: ConektaAPI

export interface ConektaFormProps extends IOFormProps<ConektaTokenResponse> {
    publicKey: string
    language: string
    children: React.ReactNode
}

function asyncTokenize(params: ConektaTokenParams): Promise<ConektaTokenResponse> {
    return new Promise((resolve, reject) => {
        Conekta.Token.create(params, (token: ConektaToken) => resolve({ token }), (error) => reject(error))
    })
}

function checkConektaCardData(card: ConektaCardParams): string[] {
    const fields = []
    if (!card.name) fields.push('name')
    if (!card.number) fields.push('number')
    if (!card.exp_year) fields.push('exp_year')
    if (!card.exp_month) fields.push('exp_month')
    if (!card.cvc) fields.push('cvc')
    return fields
}

export function ConektaForm({ publicKey, language, ...rest }: ConektaFormProps) {

    //Validate Conekta Library is present
    useEffect(() => {
        if (typeof Conekta === 'undefined') {
            throw new Error('[IOForms][Conekta]:: Conekta IOForms Library requires Conekta.js API to be present. Add conekta.js tokenizer api following these instructions: https://developers.conekta.com/libraries/javascript')
        }
        Conekta.setPublicKey(publicKey)
        Conekta.setLanguage(language)
    },[publicKey, language])

    const tokenize = useCallback(async (card: ConektaCardParams) => {
        const fieldsmissing = checkConektaCardData(card)
        if (!fieldsmissing.length) {
            rest.onSubmit(await asyncTokenize({ card }))
            return;
        }
        throw new Error(`[IOForms][Conekta]:: Missing fields ${fieldsmissing.join(', ')}. Make sure you have either built-in all the ConektaInputs or your custom inputs declared within the ConektaForm component children for the missing fields`)
    }, [rest])

    return (
        <IOForm {...rest} onSubmit={tokenize}>
            {rest.children}
        </IOForm>
    )
    
}
