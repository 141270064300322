import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import _ from "lodash";
import storage from "redux-persist/lib/storage";
import reducer from "./reducers";
import { getMessages, initLocaleData } from "./i18n";
import { DEFAULT_LANGUAGE } from "./constants";
import ReduxState from "#interfaces/ReduxState";

declare const window: any;

/**
 * Initial State and structure of the Redux Store
 * @type Object
 * @static
 * @name Redux.store
 */
export const storeInitialState: ReduxState = {
  routing: {},
  /** i18n Reducer Root Object */
  intl: {
    locale: DEFAULT_LANGUAGE,
    messages: getMessages(DEFAULT_LANGUAGE),
  },
  user: {},
  quotation: {
    selection: {},
    page: 0,
  },
  payment: {
    paypal: {},
  },
  general: {
    stats: {},
  },
};

/**
 * Configuration Object for the Module "Redux Persists" which allows the store (or part of it)
 * To persist in the browser's localStorage
 * @static
 * @see https://github.com/rt2zz/redux-persist
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "payment"],
  stateReconciler: autoMergeLevel2,
};

/** Initializes the Redux Store with all the Middlewares. */
export const initializeStore = (
  storeInitialExternal: ReduxState = storeInitialState,
  persist = true
) => {
  initLocaleData();
  /** Persisted Reducer */
  const persistedReducer = persist
    ? persistReducer(persistConfig, reducer)
    : reducer;
  // Logger to display states on console
  const logger = (st) => (next) => (action) => {
    console.group(action.type); // eslint-disable-line no-console
    console.log("%cPREVIOUS STATE %o", "color: #A8383B", st.getState()); // eslint-disable-line no-console
    console.log("%cDISPATCH %o", "color: #226764;", action); // tslint-disable-line no-console
    const result = next(action);
    console.log("%cNEXT STATE %o", "color: #338A2E;", st.getState()); // eslint-disable-line no-console
    console.groupEnd(); // tslint-disable-line
    return result;
  };
  const store = createStore(
    persistedReducer,
    _.merge(
      storeInitialState,
      storeInitialExternal,
      window.__PRELOADED_STATE__
    ),
    process.env.NODE_ENV !== "production" ? applyMiddleware(thunk, logger) : applyMiddleware(thunk)
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
