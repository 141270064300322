import './style.scss'
import React from 'react'
import { Row, Col, Container, Button } from 'reactstrap'
import ReduxState, { QuotationSelection, PaymentState } from '#interfaces/ReduxState'
import { connect } from 'react-redux'
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions'
import { QuotationPageProps } from '#interfaces/Quotation'
import Receipt from '#components/Receipt'

interface QuotationFinalInjectedProps {
    selection?: QuotationSelection
    payment?: PaymentState
}


class QuotationFinal extends React.PureComponent<QuotationPageProps & QuotationFinalInjectedProps & ThunkDispatchProp> {

    state = {
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }    

    finish = () => {
        this.props.onFinish()
    }

    render() {
        const { selection } = this.props
        return (
            <div className="container-quotationfinal">
                <Container>
                    <Row>
                        <Col className="mb-4 text-center" xs={12}>
                            <h3>¡Tu cita ha sido creada exitosamente!</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Receipt selection={selection} payment={this.props.payment.payment_method} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={{ size: 4, offset: 4 }}>
                            <Button block color="primary" className="btn-flat" onClick={this.finish}>
                                Terminar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default connect<QuotationFinalInjectedProps, {}>((state: ReduxState) => ({ 
    selection: state.quotation.selection,
    payment: state.payment
}), dispatchMap)(QuotationFinal)