import './style.scss'
import React from 'react'
import Sucursal from '#interfaces/Sucursal'
import { Row, Col } from 'reactstrap'
import { COMPANY, ROUTES } from '#constants'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import GoogleMapsAPI from '#helpers/maps/GoogleMapsAPI'

interface SucursalProps {
    sucursal: Partial<Sucursal>,
    link?: boolean,
    inverted?: boolean
}

const SucursalComponent = ({ sucursal: suc, link }: SucursalProps) => {
    const sucursal = suc || {}
    return (
        <div className="sucursal-component">
            <Row>
                <Col sm={12} md={7} lg={6} className="map">
                    <div className="img-background">
                        {/* <Img src={sucursal.image || 'sucursales/default.png'} /> */}
                        {GoogleMapsAPI.render(`${sucursal.place_id ? `place_id:${sucursal.place_id}` : `${COMPANY.NAME} ${sucursal.name}`}`, process.env.REACT_APP_GOOGLE_API_KEY, sucursal.maps)}
                    </div>
                </Col>
                <Col sm={12} md={5} lg={6} className="info p-4">
                    <Row>
                        <Col className="d-flex justify-content-center text-center mb-3">
                            <h3>{`Sucursal ${sucursal.name}`}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center text-center">
                            <p>{sucursal.address}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center text-center">
                            {sucursal.distance && <p className="text-black">{`a ${(sucursal.distance / 1000).toFixed(1)} km`}</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center text-center mt-4 mb-4">
                            <a title="Llamar por teléfono" rel="noopener noreferrer" href={`tel:${sucursal.phone}`} target="_blank">
                                <FontAwesomeIcon size="lg" icon={faPhone} />&nbsp;{sucursal.phone}
                            </a>
                        </Col>
                    </Row>
                    {link && <Row>
                        <Col className="d-flex justify-content-center text-center more">
                            <Link to={ROUTES.SHOPS}>Ver más sucursales</Link>
                        </Col>
                    </Row>}
                </Col>
            </Row>
        </div>
    )
}

export default SucursalComponent
