import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl-redux'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { initializeStore } from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

const { store, persistor } = initializeStore();

//ROOT DOM RENDER
ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<IntlProvider>
				<App />
			</IntlProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
