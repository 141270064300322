import update from 'immutability-helper';
import { PaymentState } from '#interfaces/ReduxState';

// ACTION TYPES DECLARED AT USER ACTION CONSTANTS
import { ReduxAction } from '#interfaces/ReduxActions';
import { TYPE_SET_PREVIEW_PAYMENT, TYPE_SET_PAYMENTMETHOD, TYPE_SET_PAYPAL_URL, TYPE_SET_PAYPALDATA, TYPE_SET_CONEKTADATA, TYPE_RESET_PAYMENT } from '#actions/payment';


// REDUCER FUNCTION
export default function payment ( state: PaymentState = {}, action: ReduxAction<PaymentState> ) {
	switch ( action.type ) {
		case TYPE_SET_PAYPAL_URL:
		case TYPE_SET_PAYPALDATA:
			return update(state, { paypal: { $set: action.value.paypal } })
		case TYPE_SET_CONEKTADATA:
			return update(state, { conekta: { $set: action.value.conekta } })
		case TYPE_SET_PAYMENTMETHOD:
			return update(state, { payment_method: { $set: action.value.payment_method } })
		case TYPE_SET_PREVIEW_PAYMENT:
			return update(state, {  preview: { $set: action.value.preview  }})
		case TYPE_RESET_PAYMENT:
			return update(state, { $set: {}})
		default:
			return state;
	}
}