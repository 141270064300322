import { Appointment, Cart } from "./Quotation";

export enum PaymentMethodEnum {
    PAYPAL = 'PAYPAL', CONEKTA = 'CONEKTA', PHYSICAL_TERMINAL = 'VISA/MASTERCARD', CASH = "CASH"
}

export interface PaymentCharge {
    id?: number
    amount?: number
    creationtime?: string
    enabled?: number
    idprovider?: string
    reference?: string
    idpaypalorder?: string
    paypalstatus?: string
    status?: number
    currency?: string
    idserviceorder?: string
    servicestatus?: string
}

export interface PaypalURL {
    href?: string
    rel?: string
    method?: string
}

export interface Payment {
    charge?: PaymentCharge
    appointment?: Appointment
    cart?: Cart
}

export interface PaypalPayment extends Payment {
    approve_url?: PaypalURL
    paypal?: any
}

export interface ConektaPayment extends Payment {
    conekta?: any
}
