import update from 'immutability-helper';
import { GeneralState } from '#interfaces/ReduxState';

// ACTION TYPES DECLARED AT USER ACTION CONSTANTS
import { ReduxAction } from '#interfaces/ReduxActions';
import { TYPE_SET_STATS } from '#actions/general';


// REDUCER FUNCTION
export default function general ( state: GeneralState = {}, action: ReduxAction<GeneralState> ) {
	switch ( action.type ) {
		case TYPE_SET_STATS:
			return update(state, { stats: { $set: action.value.stats } })
		default:
			return state;
	}
}