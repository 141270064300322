import { EffectCallback, useEffect, useState } from "react"

export const useEffectMount = (fn: EffectCallback) => useEffect(fn, [])
export const useEffectPartial = (fn: EffectCallback, partial: any[]) => useEffect(fn, [...partial])

export const useInterval = (fn: Function, interval: number, immediate = false) => useEffectMount(() => {
    const i = setInterval(fn, interval)
    if (immediate) {
        fn()
    }
    return () => clearInterval(i)
})

// Hook
export const useWindowSize = () => {
    const isClient = typeof window === 'object';
  
    const getSize = () => {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
      };
    }
  
    const [windowSize, setWindowSize] = useState(getSize);
  
    useEffectMount(() => {
      if (!isClient) {
        return;
      }
      const handleResize = () => {
        setWindowSize(getSize());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    });
  
    return windowSize;
  }