import './style.scss';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatchProp, dispatchMap } from '#interfaces/ReduxActions';
import _ from 'lodash';
import ReduxState, { RepairState } from '#interfaces/ReduxState';
import { Row, Col, Fade, Container } from 'reactstrap';

import MiReparacionWidget from '#containers/MiReparacionWidget';
import { ROUTES } from '#constants';
import MyRepairSummary from '#components/MyRepairSummary';
import { resetQuotation, getInitialStates } from '#actions/quotation';
import { resetPayment } from '#actions/payment';

interface RepairPropsInjected{
    repair: RepairState
}

/**
 * This Login Screen Component implements the login screen
 * @type React.Component
 */
class Repair extends React.Component<RepairPropsInjected & ThunkDispatchProp & RouteComponentProps<any>> {

    state = {}

    componentDidMount() {
        this.props.dispatch(resetQuotation())
        this.props.dispatch(resetPayment())
        this.props.dispatch(getInitialStates())
    }

    goToQuote = () => {
        const { history } = this.props
        history.push(ROUTES.QUOTE)
    }



    render () {
        const { repair } = this.props
        const show = repair && !_.isEmpty(repair)
        return (
            <div className="screen screen-repair">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <MiReparacionWidget />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    {show && <Fade>
                        <Row>
                            <Col xs={12}>
                                <MyRepairSummary repair={repair} />
                            </Col>
                        </Row>
                    </Fade>}
                </Container>
            </div>
        );
    }
}

export default connect<RepairPropsInjected>((state: ReduxState) => ({
    repair: state.repair
}), dispatchMap)(withRouter(Repair));
