
import HTTPClient from './HTTPClient';
import { APIResponse } from '#interfaces/Quotation';

export interface APISession {
    token: string
}


class APIClient extends HTTPClient<APISession> {
    static client: APIClient;
    
    static get() {
        if (!APIClient.client) {
            APIClient.client = new APIClient(process.env.REACT_APP_API_URL, {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
            })
        }
        return APIClient.client
    }

    credentials = { user: 'api_operator_f45dg34PsX55', password: 'f85hE7sT53yHskfJh4xD92' }

    
    async shouldRenegotiate(response: Response | Error): Promise<boolean> {
        if (response instanceof Response) {
            if (response.status === 401) {
                return true
            } 
        }
        return false
    }
    async renegotiateSession(): Promise<APISession> {
        return await this.requestSession()
    }

    async requestSession(): Promise<any> {
        const response = await this.request('POST','/authenticate',this.credentials, null, false)
        const result = await response.json() as APIResponse<APISession>
        return result.data
    }

    prepareHeaders(headers: HeadersInit): any {
        if (!headers) {
            headers = {}
        }
        if (this.token && this.token.token) {
            headers['token'] = this.token.token
        }
        return headers
    }

    // prepareBody(body: any): any {
    //     if (!body) {
    //         body = {}
    //     }
    //     if (this.token && this.token.token) {
    //         body['token'] = this.token.token
    //     }
    //     return body
    // }
    
}

export default APIClient
